import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CandidateInfo from '../../pages/candidateInfo';
import DenseTable from '../Table';
import { Result } from './ResultsElements';

const Results = ({results}) => (
    <>
    {/* TODO add dynamic routes that lead to /candidate/<candidate_id> */}
        {results.map(result => (
            <div style={{margin: '.1vw 1vw'}}>
                <Result to={'/liberty/candidate/' + result.candidate_id} key={result.candidate_id}>{result.name}</Result>
            </div>
        ))}
    <Routes>
        {results.map(result => (
            <Route path={'/liberty/candidate/' + result.candidate_id} element={<CandidateInfo />} key={result.candidate_id}/>
        ))}
    </Routes>
    </>
);

// function onClick () {

// }

// const Results = ({results}) => (
//     <>
//     {results.map(result => (
//         <div>
//             <a href={'candidate/' + result.candidate_id} onClick={onClick}>{result.name}</a>
//         </div>
//     ))}
//     </>
// )

export default Results;