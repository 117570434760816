import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import LoadingCircle from '../components/LoadingCircle';
import LineChart from '../components/LineChart';
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const displayFields = ['East North Central (SA)', 'East South Central (SA)', 'Middle Atlantic (SA)', 'Mountain  (SA)', 'New England  (SA)', 'Pacific  (SA)', 'South Atlantic (SA)', 'West North Central (SA)', 'USA  (SA)']
const colors = ['pink', 'red', 'orange', 'gold', 'green', 'teal', 'blue', 'purple', 'black']

export const Housing = () => {
    const [data, setData] = useState(null);

    const fetchData = async () => {
        axios.post('/labor/CUSR0000SAH1')
        // axios.post('/housing')
        .catch((errors) => console.log(errors))
        .then((response) => {
            console.log('data', response.data)
            setData(response.data)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
        {(data != undefined) ?
            <LineChart title='Housing Cost by US Region' xAxis={data.month} series={data.series} />
        : <LoadingCircle />}
        </>
    )
}