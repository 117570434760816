import React, { useState } from 'react';
import { InlineLink } from '../../components/Navbar/NavbarElements';
import capitol from '../../assets/transparent-capitol.png';

export const Life = () => {
    const [data, setData] = useState(null);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'Left',
                alignItems: 'Left',
                height: '80vh',
                color: 'white',
                margin: '0 1vw'
            }}
        >
            <div style={{margin: '10vh auto', textShadow: 'black 2px 2px', fontSize: '1.5em', zIndex: 1}}>
                <center><h1>Life</h1></center>
                <p><InlineLink to='/life/housing'>I slept and dreamt that life was joy.</InlineLink></p>
                <p>I awoke and saw that life was service.</p>
                <p>I acted and behold, <InlineLink to='/life/test'>service was joy.</InlineLink></p>
                <p>-Rabindranath Tagore</p>
            </div>
            <img src={capitol} alt='Capitol' style={{position: 'fixed', width: '95vw', zIndex: 0}}/>
        </div>
    )
}