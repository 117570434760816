import axios from 'axios';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper, { paperClasses } from '@mui/material/Paper';
import { Toggle } from '../components/Results/ResultsElements';
import { DataGrid, gridColumnsTotalWidthSelector, GridToolbar } from '@mui/x-data-grid';
import { Field, Formik } from 'formik';
import { Box, Button, Checkbox, FormControlLabel, List, ListItem, Stack, Switch, Tab, Tabs, Typography } from '@mui/material';
import LoadingCircle from '../components/LoadingCircle';
import PropTypes from 'prop-types';
import { avgFields, champList, summonerNames } from '../consts';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const Aram = () => {
    const [data, setData] = useState(null)
    const [columnList, setColumnList] = useState([])
    const [statsColumns, setStatsColumns] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const [statsLoading, setStatsLoading] = useState(false)
    const [tabNumber, setTabNumber] = useState(0)
    const [stats, setStats] = useState([])
    const [summoners, setSummoners] = useState([])
    const [perMinute, setPerMinute] = useState(false)
    const [taylorChamps, setTaylorChamps] = useState([])
    const [pawnceyChamps, setPawnceyChamps] = useState([])
    const [harlenChamps, setHarlenChamps] = useState([])
    const [denserChamps, setDenserChamps] = useState([])
    const [colinChamps, setColinChamps] = useState([])
    const [jacobChamps, setJacobChamps] = useState([])
    const [taylorPopout, setTaylorPopout] = useState(false)
    const [pawnceyPopout, setPawnceyPopout] = useState(false)
    const [harlenPopout, setHarlenPopout] = useState(false)
    const [denserPopout, setDenserPopout] = useState(false)
    const [colinPopout, setColinPopout] = useState(false)
    const [jacobPopout, setJacobPopout] = useState(false)
    const [gameCount, setGameCount] = useState(0)

    
    const fetchData = async () => {
        setTableLoading(true)
        await axios.get('/aram').catch((errors) => console.log(errors)).then((response) => {
            setData(response.data);
            setColumnList(Object.keys(response.data[0]).map((key) => {
                return {field: key, headerName: key.split(/(?=[A-Z])/).join(' '), flex: 1, minWidth: 125}
            }));
        })
        setTableLoading(false)
    }

    const fetchStats = async () => {
        setStatsLoading(true)
        // await axios.post('/aram_average', {'summoners': summoners})
        // .catch((errors) => console.log(errors)).then((response) => {
        //     setStats(response.data);
        //     if (statsColumns.length == 0) {
        //         setStatsColumns(Object.keys(response.data[0]).map((key) => {
        //             return {field: key, headerName: key.split(/(?=[A-Z])/).join(' '), flex: 1, minWidth: 125}
        //         }));
        //     }
        // })
        const filterDict = {
            method: 'or',
            filters: summoners.map((summoner) => {
                return {
                    method: 'and',
                    filters: {
                        summonerName: [summoner],
                        championName: summonerDict[summoner].champs[0]
                    }
                }
            })
        }
        
        await axios.post('/test_query', filterDict)
        .catch((errors) => console.log(errors)).then((response) => {
            setGameCount(response.data.count)
            setStats(response.data.data);
            if (statsColumns.length == 0) {
                setStatsColumns(Object.keys(response.data.data[0]).map((key) => {
                    return {field: key, headerName: key.split(/(?=[A-Z])/).join(' '), flex: 1, minWidth: 125}
                }));
            }
        })
        setStatsLoading(false)
    }

    const changeTab = (event, newTab) => {
        setTabNumber(newTab)
    }

    console.log('data', data)
    console.log('cols', columnList)


    useEffect(()=>{
        fetchData()
    }, [])

    // useEffect(()=>{
    //     fetchStats()
    // }, [summoners])

    const summonerDict = {
        'Glaleon': {
            'champs': [denserChamps, setDenserChamps],
            'popout': [denserPopout, setDenserPopout]
        },
        'Kilowog': {
            'champs': [harlenChamps, setHarlenChamps],
            'popout': [harlenPopout, setHarlenPopout]
        },
        'KrispyK9': {
            'champs': [jacobChamps, setJacobChamps],
            'popout': [jacobPopout, setJacobPopout]
        },
        'Pawncey': {
            'champs': [pawnceyChamps, setPawnceyChamps],
            'popout': [pawnceyPopout, setPawnceyPopout]
        },
        'Timfromsweden': {
            'champs': [taylorChamps, setTaylorChamps],
            'popout': [taylorPopout, setTaylorPopout]
        },
        'Ventidus': {
            'champs': [colinChamps, setColinChamps],
            'popout': [colinPopout, setColinPopout]
        }
    }


    return (
        <Paper style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabNumber} onChange={changeTab} aria-label="basic tabs example">
                    <Tab label="Full Table" {...a11yProps(0)} style={{ fontWeight: 'bolder' }} />
                    <Tab label="Averages" {...a11yProps(1)} style={{ fontWeight: 'bolder' }} />
                    {(tabNumber == 1) ? <div style={{display: 'flex', alignItems: 'center'}}>
                        <Stack direction='column' spacing={0} style={{alignItems: 'center', marginLeft: '1vw'}}>
                            <Typography>Per Minute</Typography>
                            <Switch
                                size='small'
                                color='success'
                                checked={perMinute}
                                onChange={() => setPerMinute(!perMinute)}
                            />
                        </Stack>
                        <Button
                            variant='contained'
                            onClick={fetchStats}
                            size='small'
                            style={{height: '30px', marginLeft: '1vw'}}
                            color='success'
                        >Fetch</Button>
                        <h3 style={{marginLeft: '1vw'}}># Games: {gameCount}</h3>
                    </div> : <></>}
                </Tabs>
                
            </Box>
            <CustomTabPanel value={tabNumber} index={0}>
                {(!tableLoading) ? (
                        <>
                            {(data != undefined) ? 
                                <DataGrid
                                    columns={columnList}
                                    rows={data}
                                    // getRowId={(row) => row.rowId}
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    style={{background: 'white', margin: '1vw 0', height: '80vh'}}
                            /> : <></>
                            }
                        </>
                    ) : (
                        <LoadingCircle />
                )}
            </CustomTabPanel>
            <CustomTabPanel value={tabNumber} index={1}>
                {(statsLoading) ? <div style={{position: 'fixed', display: 'flex', justifyContent: 'center', width: '100vw', zIndex: 2, paddingTop: '5vh'}}>
                    <LoadingCircle />
                </div> : <></>
                }
                <div style={{display: 'flex'}}>
                    <List>
                        <h3>Summoners</h3>
                        {summonerNames.map((summoner) => {
                            return <ListItem>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    checked={summoners.includes(summoner)}
                                    label={summoner}
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: 12 } }}
                                    onChange={() => {
                                        if (summoners.includes(summoner)) {
                                            setSummoners(summoners.filter((i) => i != summoner))
                                            summonerDict[summoner].popout[1](false)
                                            summonerDict[summoner].champs[1]([])
                                        } else {
                                            setSummoners([...summoners, summoner])
                                        }
                                    }}
                                />
                                {(summoners.includes(summoner) && !summonerDict[summoner].popout[0]) ? <>
                                    <ArrowRightIcon
                                        // onClick={()=> setDenserPopout(!denserPopout)}
                                        sx={{marginRight: 0, marginLeft: 'auto', color: summonerDict[summoner].champs[0].length == 0 ? 'black' : '#00fffb', fontSize: 40}}
                                        onClick={()=> summonerDict[summoner].popout[1](!summonerDict[summoner].popout[0])}
                                    />
                                </> : (summoners.includes(summoner) && summonerDict[summoner].popout[0]) ? <>
                                    <ArrowLeftIcon
                                        // onClick={()=> setDenserPopout(!denserPopout)}
                                        sx={{marginRight: 0, marginLeft: 'auto', color: 'gray', fontSize: 40}}
                                        onClick={()=> summonerDict[summoner].popout[1](!summonerDict[summoner].popout[0])}
                                    />
                                </> : <></>}
                                </ListItem>
                        })}
                    </List>
                    {Object.keys(summonerDict).map((summoner) => {
                        return <>
                            {(summonerDict[summoner].popout[0]) ? <>
                            <List>
                                <Paper style={{overflow: 'auto', height: 'calc(100vh - 180px)', width: '180px'}}>
                                    <h3 style={{textAlign: 'center'}}>{summoner}</h3>
                                    {champList.map((champ) => {
                                        const setChamps = summonerDict[summoner].champs[1]

                                        return <ListItem>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                checked={summonerDict[summoner].champs[0].includes(champ)}
                                                label={champ}
                                                sx={{ '& .MuiFormControlLabel-label': { fontSize: 12 }, marginBottom: '-30px' }}
                                                onChange={() => {
                                                    if (summonerDict[summoner].champs[0].includes(champ)) {
                                                        setChamps(summonerDict[summoner].champs[0].filter((i) => i != champ))
                                                    } else {
                                                        setChamps([...summonerDict[summoner].champs[0], champ])
                                                    }
                                                }}
                                            />
                                        </ListItem>
                                    })}
                                </Paper>
                            </List>
                            </> : <></>}
                        </>
                        
                    })}
                    
                    <DataGrid
                        columns={statsColumns}
                        rows={stats}
                        // getRowId={(row) => row.rowId}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        style={{background: 'white', margin: '1vw 0', height: '80vh'}}
                    />
                </div>
                
            </CustomTabPanel>
                
                


        
        </Paper>
    )
}
