import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field } from 'formik';
import Results from '../components/Results';
import CandidateInfo from './candidateInfo';
import { NavLink, Route, Routes } from 'react-router-dom';


// class Candidate extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             searchResults: [],
//             text: ''
//         };
//         this.handleSubmit = this.handleSubmit.bind(this)
//     }


//     handleSubmit(values) {
//         console.log('Values:', values)
//         axios.post('/candidate', values)
//         .then(response => {
//             console.log(response.data)
//             this.setState({searchResults: response.data})
//         })
//         this.setState({text: 'test'})
//         console.log('State:', this.state.searchResults)
//     }
//     // TODO Add results page (https://thenewstack.io/learn-react-add-event-functionality-to-a-component/)?
//     render () {
//         return (
//             <div>
//                 <p style={{margin: '1vw', color: '#ffffff', fontWeight: 'bold'}}>Search Candidate</p>
//                 <Formik
//                     initialValues={{ candidate_name: ''}}
//                     onSubmit={(values, { setSubmitting }) => {
//                         this.handleSubmit(values);
//                         setSubmitting(false);
//                     }}
//                 >
//                     {({
//                         values,
//                         errors,
//                         touched,
//                         handleChange,
//                         handleBlur,
//                         handleSubmit,
//                         isSubmitting,
//                     }) => (
//                         <table style={{margin: '1vw'}}>
//                         <tbody>
//                             <tr>
//                                 <td><form onSubmit={handleSubmit}>
//                                     <Field
//                                         name='candidate_name'
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         value={values.candidate_name}
//                                     />
//                                 </form></td>
//                                 <td><button onClick={handleSubmit} type='submit'>Search</button></td>
//                             </tr>
//                         </tbody>
//                         </table>
//                     )
//                     }
//                 </Formik>
//                 <Results results={this.state.searchResults} />

//             </div>
//         )
//     };
// }

const Candidate = () => {
    const [searchResults, setSearchResults] = React.useState([])

    const handleSubmit = (values) => {
        console.log('Values:', values)
        axios.post('/candidate', values)
        .then(response => {
            console.log(response.data)
            setSearchResults(response.data)
        })
        console.log('State:', searchResults)
    }

    return (
        <div>
            <p style={{margin: '1vw', color: '#ffffff', fontWeight: 'bold'}}>Search Candidate</p>
            <Formik
                initialValues={{ candidate_name: ''}}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <table style={{margin: '1vw'}}>
                    <tbody>
                        <tr>
                            <td><form onSubmit={handleSubmit}>
                                <Field
                                    name='candidate_name'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.candidate_name}
                                />
                            </form></td>
                            <td><button onClick={handleSubmit} type='submit'>Search</button></td>
                        </tr>
                    </tbody>
                    </table>
                )
                }
            </Formik>
            <Results results={searchResults} />

        </div>
    )

}

export default Candidate;