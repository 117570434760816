import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DenseTable from '../components/Table';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingCircle from '../components/LoadingCircle';

const CandidateInfo = () => {
    const { id } = useParams();
    const [data, setData] = React.useState([])

    const fetchData = async (candidate_id) => {
        await axios.get('/candidate/' + candidate_id).catch((errors) => console.log(errors)).then((response) => setData(response.data))
    }

    useEffect(()=>{
        fetchData(id);
    }, [])
    
    return (
        <>
        {(data&&data.length > 0) ? (
        <>
        <DenseTable data={data}/>
        </>) :
        <LoadingCircle />
        }
        </>
    )
};

export default CandidateInfo;