import React, { useState } from 'react';
import { InlineLink } from '../../components/Navbar/NavbarElements';
import capitol from '../../assets/transparent-capitol.png';

export const Liberty = () => {
    const [data, setData] = useState(null);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'Left',
                alignItems: 'Left',
                height: '80vh',
                color: 'white',
                margin: '0 1vw'
            }}
        >
            <div style={{margin: '10vh auto', textShadow: 'black 2px 2px', fontSize: '1.5em', zIndex: 1}}>
                <center><h1>Liberty</h1></center>
                <p>I believe in Liberty for all men:</p>
                <p>the space to stretch their arms and their souls,</p>
                <p>the right to breathe and <InlineLink to='/liberty/candidate'>the right to vote,</InlineLink></p>
                <p>the freedom to choose their friends,</p>
                <p>enjoy the sunshine, and ride on the railroads,</p>
                <p>uncursed by color; thinking, dreaming, working</p>
                <p>as they will in a kingdom of beauty and love.</p>
                <p>-W. E. B. Du Bois</p>
            </div>
            <img src={capitol} alt='Capitol' style={{position: 'fixed', width: '95vw', zIndex: 0}}/>
        </div>
    )
}