import { CircularProgress } from "@mui/material"


export default function LoadingCircle () {
    return (
        <div style={{
            height: '50px',
            width: '50px',
            borderRadius: '50%',
            margin: '10vh auto',
            backgroundColor: 'gray',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            color:'white'}}
        >
            <CircularProgress color='inherit'/>
        </div>
    )
}
