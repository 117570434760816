import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Result = styled(Link)`
    color: #ffffff;
`

export const Toggle = styled.input`
    height: 10px;
    width: 10px;
`