import { Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";


export const CustomAutocomplete = styled(Autocomplete) ({
    root: {
        // color: 'white'
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "white"
    },
    '& .MuiFormLabel-root': {
        color: 'white',
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "white",
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "white"
        }
      }
})


export const CustomTextField = styled(TextField) ({
  input: {
    color: 'white'
  },
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
})