import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import { Formik, Field } from 'formik';
import About from './pages/about';
import Navbar from './components/Navbar';
import Candidate from './pages/candidateSearch';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/index';
import DenseTable from './components/Table';
import CandidateInfo from './pages/candidateInfo';
import { Aram } from './pages/aram';
import { Housing } from './pages/housing';
import { Life } from './pages/life';
import { Liberty } from './pages/liberty';
import { Happiness } from './pages/happiness';
import { Wages } from './pages/wages';
import Chart from './pages/charts';
import TagManager from 'react-gtm-module'
import { Login } from './pages/login';
import { Map } from './pages/map';
import { Treemap } from './pages/treemap';
import { CampaignContributions } from './pages/campaignContributions';
import { Ocean } from './pages/water.js';

const tagManagerArgs = {
    gtmId: 'G-XFN5XRF7MF'
}

TagManager.initialize(tagManagerArgs)

const App = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [userData, setUserData] = useState({})

    useEffect(() => {
        axios.get('/session')
        // .catch((errors) => console.log(errors))
        .then((response) => {
          console.log('response', response.data)
          if (Object.keys(response.data).length > 0) {
            console.log('logged in')
            setLoggedIn(true)
            setUserData(response.data)
          } else {
            console.log('logged out')
            setLoggedIn(false)
          }
        })
      },[])

      useEffect(() => {
        if (loggedIn) {
          axios.get('/session')
          .catch((errors) => console.log(errors))
          .then((response) => {
              setUserData(response.data)
          })
        }
      }, [loggedIn])

      useEffect(() => {
        console.log('userData', userData)
      }, [userData])
    
        return (
            <div>
            <Router>
                <Navbar loggedIn={loggedIn}/>
                <Routes>
                    {/* <Route path='/' exact element={<Home />} /> */}
                    <Route path='/' element={<Home userData={userData} />} />
                    <Route path='/map' element={<Map userData={userData} />} />
                    <Route path='/chart' element={<Chart userData={userData} />} />
                    <Route path='/budget' element={<Treemap />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/life' element={<Life />} />
                    <Route path='/liberty' element={<Liberty />} />
                    <Route path='/happiness' element={<Happiness />} />
                    <Route path='/happiness/wages' element={<Wages />} />
                    <Route path='/liberty/candidate/*' element={<Candidate />} />
                    <Route path='/liberty/candidate/:id' element={<CandidateInfo />} />
                    <Route path='/aram' element={<Aram />} />
                    <Route path='/campaign_contributions' element={<CampaignContributions />} />
                    <Route path='/life/housing' element={<Housing />} />
                    <Route path='/life/test' element={<Chart inputSeries={['LNS11000000', 'LNS12000000', 'LNS13000000']} userData={userData} />} />
					<Route path='/air_temperature' element={<Ocean />} />
                    {/* <Route path='/charts' element={<Chart userData={userData} />} /> */}
                    <Route path='/login' element={<Login setToken={setLoggedIn} loggedIn={loggedIn} userData={userData} />} />
                    {/* <Route path='/liberty' element={<Liberty />} />
                    <Route path='/happiness' element={<Happiness />} /> */}
                    {/* <Route path='/candidate/:id' element={(props) => <CandidateInfo {...props} />} /> */}
                </Routes>
            </Router>
            </div>

        )
    }

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<Form />);

export default App;
