import WaterWave from 'react-water-wave';
import image from  '../assets/ocean.jpg';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { Checkbox, FormControlLabel, Paper, Tab, Tabs, Box, Typography, Button, Slider, TextField, MenuItem } from '@mui/material';
import { weatherModels } from '../consts';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import ReactMapGL, { Source, Layer } from 'react-map-gl';
import { create as ihml } from 'interpolateheatmaplayer';
// import vec3 from 'vec3';
import { Panel, PanelGroup } from "react-resizable-panels";
import ResizeHandle from '../components/ResizeHande';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LinearProgress from '@mui/material/LinearProgress';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const fetchData = (startDate, endDate, models, longitude, latitude, setData) => {
	// const url = 'https://climate-api.open-meteo.com/v1/climate?latitude=52.52&longitude=13.41&start_date=2000-01-01&end_date=2000-12-31&models=CMCC_CM2_VHR4,FGOALS_f3_H,HiRAM_SIT_HR,MRI_AGCM3_2_S,EC_Earth3P_HR,MPI_ESM1_2_XR,NICAM16_8S&daily=temperature_2m_max'
	// const url = 'https://climate-api.open-meteo.com/v1/climate'
	// var params = {
		// longitude: longitude,
		// latitude: latitude,
		// start_date: startDate,
		// end_date: endDate,
		// models: models.join(','),
		// daily: 'temperature_2m_max',
	// }
	// axios.get(url, {params: params})
	// .catch((error) => console.log('error', error))
	// .then((response) => {
		// console.log('response', response)
		// setData(response.data)
	// })
// }

const fetchData = async (startDate, endDate, model, days, months, years, setData, setLoading) => {
	setLoading(true)
	const postBody = {
		start_date: startDate.format('YYYY-MM-DD'),
		end_date: endDate.format('YYYY-MM-DD'),
		model: model,
		days: days,
		months: months,
		years: years
	}
	
	await axios.post('/global_heat', postBody)
	.catch((error) => console.log('error', error))
	.then((response) => {
		console.log('response', response.data)
		setData(response.data.data)
	})
	setLoading(false)
}


const refreshData = (refresh, setRefresh, dates) => {
	if ((refresh + 1) >= dates.length) {
		setRefresh(0)
	} else {
		setRefresh(refresh + 1)
	}
}


export const Ocean = () => {
	const [data, setData] = useState({dates: [], temperatures: []})
	const [startDate, setStartDate] = useState(dayjs('2001-01-01'))
	const [endDate, setEndDate] = useState(dayjs('2024-08-01'))
	const [model, setModel] = useState('temperature_2m_mean')
	const [frequency, setFrequency] = useState('monthly')
	const [days, setDays] = useState(0)
	const [months, setMonths] = useState(1)
	const [years, setYears] = useState(0)
	const [longitude, setLongitude] = useState(13.41)
	const [latitude, setLatitude] = useState(52.52)
	const [tabNumber, setTabNumber] = useState(0)
	const [tableColumns, setTableColumns] = useState([])
	const [tableRows, setTableRows] = useState([])
	const mapRef = useRef(null);
	const [refresh, setRefresh] = useState(-1);
	const [refreshSpeed, setRefreshSpeed] = useState(100)
	const [cycle, setCycle] = useState(false)
	const [maxTemp, setMaxTemp] = useState(0)
	const [meanTemp, setMeanTemp] = useState(0)
	const [minTemp, setMinTemp] = useState(0)
	const [loading, setLoading] = useState(false)

	const changeTab = (event, newTab) => {
        setTabNumber(newTab)
    }

	// useEffect(()=>{
			// fetchData(startDate, endDate, model, days, months, years, setData)
	// }, [startDate, endDate, model])

	useEffect(()=>{
		console.log('data', data)
		if (data.temperatures.length > 0) {
			var maxTemp = 0
			var minTemp = 0
			const allTemps = data.temperatures.flat()
			allTemps.map((i) => {
				if (Math.max(i) > maxTemp) {
					maxTemp = Math.max(i)
				}
				if (Math.min(i) < minTemp) {
					minTemp = Math.min(i)
				}
			})
			setMaxTemp(maxTemp)
			setMinTemp(minTemp)
			setMeanTemp(Math.round((allTemps.reduce((a,b)=> a + b) / allTemps.length) * 10) / 10)
		}
	},[data])


	useEffect(()=>{
		let interval
		if (cycle) {
			interval = setInterval(()=>refreshData(refresh, setRefresh, data.dates), refreshSpeed)
		} else {
			clearInterval(interval)
		}

		return () => {
			clearInterval(interval)
		}
	})


	useEffect(()=>{
		fetchData(startDate, endDate, model, days, months, years, setData, setLoading)
	}, [])

	
	useEffect(()=>{
		if (frequency == 'monthly') {
			setDays(0)
			setMonths(1)
			setYears(0)
		} else if (frequency == 'annually') {
			setDays(0)
			setMonths(0)
			setYears(1)
		}
	}, [frequency])


	useEffect(()=>{
		if (mapRef.current) {
			const points = data.coordinates.map((coords, ind) => {
				const out = {
					lat: data.coordinates[ind][1],
					lon: data.coordinates[ind][0],
					val: data.temperatures[refresh][ind],
				}
				return out
			})
			const map = mapRef.current.getMap()
						
			if (map.getLayer('interpolate-heatmap')) {
				map.removeLayer('interpolate-heatmap')
			}

			const heatmapLayer = ihml({
				layerId: 'interpolate-heatmap',
				points: points,
				opacity: 0.6,
				minValue: minTemp,
				maxValue: maxTemp,
				// roi: roi,
				// p: 3,
				p: 5,
				framebufferFactor: 0.10,
				// valueToColor: (tmp, value) => {
					// console.log('value', value)
					// if (value < 0) {
						// return vec3(value/0.3333, 0.0, 0.0)
					// } else if (value < 12) {
						// return vec3(1., (value-0.3333) / 0.3333, 0.0)
					// } else {
						// console.log('here')
						// const out = vec3(1. - (value-0.6666) / 0.3333, 1.0, 0.0)
						// console.log('here2')
						// return out
					// }
				// },
			  })

			  map.addLayer(heatmapLayer);
			// Clean up the layer when component unmounts
			// return () => {
			  // if (map.getLayer('interpolate-heatmap')) {
				// map.removeLayer('interpolate-heatmap');
				// map.removeSource('interpolate-heatmap-source');
			  // }
			// };

			
		}

	}, [refresh])


	return <>
		<div style={{height: 'calc(100vh - 80px', display: 'flex', flexDirection: 'column'}}>
			<div style={{ padding: '0 30px' }}>
				<Slider 
					aria-label='Custom marks'
					defaultValue={0}
					min={0}
					max={data['dates'].length - 1}
					value={refresh}
					valueLabelDisplay='auto'
					valueLabelFormat={(value) => data['dates'][value]}
					onChange={(event, value) => {
						setCycle(false)
						setRefresh(value)
					}}

					sx={{
						color: '#29a329',
						'& .MuiSlider-markLabel': {color: 'white', fontWeight: 'bold'}
					}}
					marks={data['dates'].map((date, ind) => {
						var label = (data['dates'].length < 146) ? "'" : ''
						if (date.slice(5,7) == '01') {
							label = date.slice(0,4)
						} else if (['04', '07', '10'].includes(date.slice(5,7)) && data['dates'].length < 146) {
							label = '|'
						// } else if (['04', '10'].includes(date.slice(5,7))) {
							// label = "'"
						} else if (date.slice(5,7) == '07') {
							label = "'"
						}
						return {value: ind, label: label}
					})}
				/>
			
			</div>
			<PanelGroup direction='horizontal'>
				<Panel defaultSize={15}>
					<Paper
						style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center' }}
					>
						<Button
							onClick={()=> {
								// refreshData(refresh, setRefresh, data.dates)
								setCycle(!cycle)
							}}
							// sx={{marginTop: '1px'}}
							color='success'
							variant='contained'
							disabled={data['dates'].length == 0}
						>
							{(!cycle) ? <PlayArrowIcon size='large' /> : <PauseIcon size='large' />}
						</Button>
						<TextField
							select
							label="Speed"
							value={refreshSpeed}
							sx={{margin: '10px'}}
							onChange={(event) => setRefreshSpeed(event.target.value)}
						>
							<MenuItem value={1000}>1x</MenuItem>
							<MenuItem value={500}>2x</MenuItem>
							<MenuItem value={100}>3x</MenuItem>
						</TextField>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label='Start Date'
								value={startDate}
								maxDate={dayjs('2024-08-01')}
								minDate={dayjs('2001-01-01')}
								onChange={(value) => setStartDate(value)}
								sx={{margin: '10px', marginTop: 0}}
							/>
							<DatePicker
								label='End Date'
								value={endDate}
								maxDate={dayjs('2024-08-01')}
								minDate={dayjs('2001-01-01')}
								onChange={(value) => setEndDate(value)}
								sx={{marginX: '10px'}}
							/>
						</LocalizationProvider>
						<TextField
							select
							label='Frequency'
							value={frequency}
							sx={{margin: '10px'}}
							onChange={(event) => setFrequency(event.target.value)}
						>
							<MenuItem value='monthly'>Monthly</MenuItem>
							<MenuItem value='annually'>Annually</MenuItem>
						</TextField>
						<Button
							variant='contained'
							disabled={loading}
							onClick={() => fetchData(startDate, endDate, model, days, months, years, setData, setLoading)}
						>Fetch</Button>
					</Paper>
				</Panel>
				<ResizeHandle />
				<Panel
					onResize={()=>{
                        if (mapRef.current) mapRef.current.resize()
                    }}
				>
					<div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
						<div style={{ width: '100%', height: '100%' }} >
							{(loading) ? <LinearProgress color='success'/> : <></>}
							<ReactMapGL
								ref={mapRef}
								mapboxApiAccessToken='pk.eyJ1IjoidGF5bG9yayymJvb25lIiwiYSI6ImNsNXgyaDl1YTBwdGszYmwzeXN6d216MGIifQ.6_saSHw5pcQbH-bfrDCAwA'
								// mapStyle="mapbox://styles/taylorjboone/cln7q2zfh078h01qbgus6d7e5"
								mapStyle="mapbox://styles/taylorjboone/clzbz1tg0005801qpb7y9cy06"
								renderWorldCopies={false}
								initialViewState={{bounds: [[-180, -70],[180,90]]}}
								maxBounds={[[-180,-90],[180,90]]}
							>
							</ReactMapGL>
						</div>
						<div style={{ width: '35px', backgroundImage: 'linear-gradient(red, green, blue)', borderRadius: '0 5px 5px 0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: 'white', fontWeight: 'bold' }} >
							<div style={{ textAlign: 'center' }}>{maxTemp} C</div>
							<div style={{ textAlign: 'center' }}>{meanTemp} C</div>
							<div style={{ textAlign: 'center' }}>{minTemp} C</div>
						</div>

					</div>
				</Panel>
			</PanelGroup>
		</div>
	</>
	}
