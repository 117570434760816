import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import HC_Exporting from 'highcharts/modules/exporting'
import HC_ExportingOffline from 'highcharts/modules/offline-exporting';
import { Paper } from '@mui/material';

HC_Exporting(Highcharts)
HC_ExportingOffline(Highcharts)

const LineChart = (props) => {
    const {
        title = '',
        xAxis = [],
        series = []
    } = props

    const options = {
        title: {
            text: title
        },
        chart: {
            type: 'line'
        },
        xAxis: {
            categories: xAxis
        },
        yAxis: {
            title: {
                text: 'Percent Change'
            }
        },
        series: series,
        credits: {
            enabled: false
        },
        exporting: {
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            },
            fallbackToExportServer: false
        }
    }

    return (
        // <div style={{width: '90vw', height: '80vh'}}>
            // <div style={{height: '100%', width: '100%', backgroundColor: '#666666', padding: '2%'}}>
            <Paper style={{height: '100%', width: '100%', margin: 'auto'}}>
                <HighchartsReact 
                    highcharts={Highcharts}
                    options={options}
                    containerProps={{style: {height: '100%', width: '100%'}}}
                />
            </Paper>
                
            // </div>
        // </div>
    )
}

export default LineChart;