import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { statesGeojson } from '../../consts';
import { createRoot } from 'react-dom/client';
import { Box, MenuItem, Paper, Popper, Stack, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import acsSeries from '../../consts/acsSeries.json'
import uniqueConcepts from '../../consts/uniqueConcepts.json'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import LoadingCircle from '../../components/LoadingCircle';
import Highcharts, { setOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from "highcharts/modules/drilldown.js";
import { CustomAutocomplete, CustomTextField } from '../../components/CustomAutocomplete';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Panel, PanelGroup } from "react-resizable-panels";
import ResizeHandle from '../../components/ResizeHande';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

drilldown(Highcharts)

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

const colors = Highcharts.getOptions().colors.map((c, i) => {
    // Start out with a darkened base color (negative brighten), and end
    // up with a much brighter color
    console.log(Highcharts.color(Highcharts.getOptions().colors));
    // return Highcharts.color(Highcharts.getOptions().colors[2])

    const outColor = Highcharts.color('#22c700')
        .brighten((i - 3) / 10)
        .get()

    return outColor
});



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



mapboxgl.accessToken = 'pk.eyJ1IjoidGF5bG9yamJvb25lIiwiYSI6ImNsNXgyaDl1YTBwdGszYmwzeXN6d216MGIifQ.6_saSHw5pcQbH-bfrDCAwA'
const censusApiKey = '8c748776acc09bfa95e11ce06000a0ee29392ba8'

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function sortLabelText(a,b) {
    if (a.displayLabel > b.displayLabel) {
        return 1
    } else if (a.displayLabel < b.displayLabel) {
        return -1
    } else {
        return 0
    }
}

// {
//     "chart": {
//       "type": "pie"
//     },
//     "tooltip": {
//       "pointFormat": "{series.name}: <b>{point.y} Tons (TN)</b>"
//     },
//     "title": {
//       "style": {
//         "fontWeight": "bold"
//       },
//       "text": "Stabilization - Shoulders"
//     },
//     "credits": {
//       "enabled": false
//     },
//     "series": [
//       {
//         "name": "Activity",
//         "data": [
//           {
//             "name": "Planned",
//             "y": 1747
//           },
//           {
//             "name": "Accomplished: 72%",
//             "y": 1264
//           }
//         ],
//         "size": "60%",
//         "dataLabels": {
//           "distance": "-50%"
//         }
//       },
//       {
//         "name": "Orgs",
//         "data": [
//           {
//             "accomplishments": 579,
//             "activity_description": "Stabilization - Shoulders",
//             "color": "#ffba00",
//             "drilldown": "0167",
//             "name": "0167",
//             "planned": 794,
//             "units": "Tons (TN)",
//             "y": 794
//           },
//           {
//             "accomplishments": 640,
//             "activity_description": "Stabilization - Shoulders",
//             "color": "#f2b100",
//             "drilldown": "0197",
//             "name": "0197",
//             "planned": 829,
//             "units": "Tons (TN)",
//             "y": 829
//           },
//           {
//             "accomplishments": 45,
//             "activity_description": "Stabilization - Shoulders",
//             "color": "#e6a800",
//             "drilldown": "0198",
//             "name": "0198",
//             "planned": 124,
//             "units": "Tons (TN)",
//             "y": 124
//           },
//           {
//             "accomplishments": 45,
//             "activity_description": "Stabilization - Shoulders",
//             "color": "#00244d",
//             "drilldown": "0198",
//             "name": "0198",
//             "planned": 124,
//             "units": "Tons (TN)",
//             "y": 45
//           },
//           {
//             "accomplishments": 640,
//             "activity_description": "Stabilization - Shoulders",
//             "color": "#002c5e",
//             "drilldown": "0197",
//             "name": "0197",
//             "planned": 829,
//             "units": "Tons (TN)",
//             "y": 640
//           },
//           {
//             "accomplishments": 579,
//             "activity_description": "Stabilization - Shoulders",
//             "color": "#003570",
//             "drilldown": "0167",
//             "name": "0167",
//             "planned": 794,
//             "units": "Tons (TN)",
//             "y": 579
//           }
//         ],
//         "dataLabels": {
//           "distance": "10%"
//         },
//         "size": "80%",
//         "innerSize": "60%"
//       }
//     ],
//     "exporting": {
//       "enabled": false
//     },
//     "drilldown": {
//       "series": [
//         {
//           "data": [
//             {
//               "name": "Planned",
//               "y": 794
//             },
//             {
//               "name": "Accomplished",
//               "y": 579
//             }
//           ],
//           "id": "0167",
//           "name": "0167"
//         },
//         {
//           "data": [
//             {
//               "name": "Planned",
//               "y": 829
//             },
//             {
//               "name": "Accomplished",
//               "y": 640
//             }
//           ],
//           "id": "0197",
//           "name": "0197"
//         },
//         {
//           "data": [
//             {
//               "name": "Planned",
//               "y": 124
//             },
//             {
//               "name": "Accomplished",
//               "y": 45
//             }
//           ],
//           "id": "0198",
//           "name": "0198"
//         }
//       ]
//     },
//     "plotOptions": {
//       "pie": {
//         "tooltip": {
//           "pointFormat": "{series.name}: <b>{point.y} Tons (TN)</b>"
//         },
//         "center": [
//           "50%",
//           "50%"
//         ],
//         "colors": [
//           "#EAAA00",
//           "#002855"
//         ]
//       }
//     }
//   }

const updateCharts = (mapData, selectedState, setOptions) => {
    console.log('selected state', selectedState)
    console.log('**mapData**', mapData)
    const topLabels = mapData.filter((i) => i.NAME == selectedState)[0].data.filter((i) => i.label.length == 2)
    const statePop = mapData.filter((i) => i.NAME == selectedState)[0].data.filter((i) => i.label.length == 1)
    const secondLabels = mapData.filter((i) => i.NAME == selectedState)[0].data.filter((i) => i.label.length == 3).filter((i) => arraysEqual(i.label.slice(0,2), topLabels[0].label.slice(0,2)))

    const secondSeriesData = [
        { name: 'Not Included', y: statePop[0].data - topLabels[0].data },
        ...secondLabels.filter((i) => !['Estimate Total: 2.00 and over', 'Estimate Total: .50 to .99'].includes(i.displayLabel)).map((i) => {
            const outRecord = {}
            outRecord.y = i.data
            outRecord.drilldown = i.displayLabel
            outRecord.name = i.label.slice(-1)
            return outRecord
        }),
    ]

    const colors = secondSeriesData.map((x, index) => Highcharts.color('#22c700').brighten((index - 6) / (secondSeriesData.length + 1)).get())

    const newOption = {
        chart: {
            type: 'pie',
            events: {
                load: () => {
                    console.log('load e')
                    this.reflow()
                }
            }
        },
        tooltip: {
            pointFormat: `{series.name}: <b>{point.y}</b>`
        },
        title: {
            style: { fontWeight: 'bold' },
            text: topLabels[0].concept
        },
        credits: {
            enabled: false
        },
        series: [{
            name: '',
            data: [
                { name: 'Not Included', y: statePop[0].data - topLabels[0].data },
                { name: 'Estimate Total', y: topLabels[0].data, drilldown: 'Estimate Total' },
            ],
            size: '60%',
            dataLabels: {
                distance: '-50%',
                formatter: function () {
                    if (this.y > 0) {
                        return this.key;
                    }
                }
            },
        }, {
            name: '',
            data: secondSeriesData,
            dataLabels: {
                distance: '10%',
                formatter: function () {
                    if (this.y > 0) {
                        return this.key;
                    }
                }
            },
            size: '80%',
            innerSize: '60%'
        }],
        // exporting: {
        //     enabled: false
        // },
        drilldown: {
            series: [{
                id: 'Estimate Total',
                name: '',
                data: secondLabels.filter((i) => !['Estimate Total: 2.00 and over', 'Estimate Total: .50 to .99'].includes(i.displayLabel)).map((i) => {
                    return {'name': i.displayLabel, 'y': i.data}
                }),
                size: '80%'
            }]
            // series: secondLabels.map((i) => {
            //     return {
            //         id: i.displayLabel,
            //         name: i.displayLabel,
            //         data: [{'name': i.displayLabel, 'y': i.data}],
            //     }
            // })
        },

        plotOptions: {
            pie: {
                tooltip: {
                    pointFormat: `{series.name}: <b>{point.y}</b>`
                },
                // dataLabels: {
                //     distance: '-50%',
                //     formatter: function(){
                //         if (this.y > 0) {
                //             return this.key;
                //         }
                //     }
                // },
                center: ['50%', '50%'],
                // size: '100%',
                // colors: ['#004f15', '#009127']
                colors: colors,
            }
        }
    }
    console.log('newOption', newOption)
    setOptions([newOption])
}

const fetchCensusSeries = async (concept, setMapData, setSeriesId) => {
    // const r = await axios.get('https://api.census.gov/data.json')
    // const metadata = await axios.get('https://api.census.gov/data.json', {headers: {'Content-Type': 'application/json'}})
    // const metadata = await axios.get('https://api.census.gov/data/2022/acs/acs1/variables.json', {headers: {'Content-Type': 'application/json'}})
    // const seriesMetadata = metadata.data.variables[seriesId]
    // console.log('metadata', seriesMetadata)

    const seriesMetadata = acsSeries.filter((i) => i.concept == concept)
    console.log('metadata', seriesMetadata)
    const conceptSeries = 'B01003_001E,' + seriesMetadata.map((i) => i.seriesId).slice(0, 48).join(',')

    // const totalPop = await axios.get('https://api.census.gov/data/2022/acs/acs1', { headers: { 'Content-Type': 'application/json'}, params: {'get': 'NAME,B01001_001E', 'for': 'state:*', 'key': censusApiKey}})
    // console.log('totalPop', totalPop)

    // const r = await axios.get('https://api.census.gov/data/2022/acs/acs1', { headers: { 'Content-Type': 'application/json'}, params: {'get': 'NAME,group(B17002)', 'for': 'state:*', 'key': censusApiKey}})
    // const r = await axios.get('https://api.census.gov/data/2022/acs/acs1', { headers: { 'Content-Type': 'application/json'}, params: {'get': `NAME,${seriesId}`, 'for': 'state:*', 'key': censusApiKey}})
    const r = await axios.get('https://api.census.gov/data/2022/acs/acs1', { headers: { 'Content-Type': 'application/json' }, params: { 'get': `NAME,${conceptSeries}`, 'for': 'state:*', 'key': censusApiKey } })

    // const outData = r.data.slice(1).map((i) => {
    //     const statePop = parseInt(totalPop.data.filter((j)=>j[0] == i[0])[0][1])
    //     return {'state': i[0], 'data': i[1], 'stateCode': i[2], 'totalPop': statePop}
    // })
    // console.log('outData', outData)


    const estimateTotalMetadata = acsSeries.filter((i) => i.concept == concept && i.label.split('!!').length == 2)
    console.log('estimateTotalMetadata', estimateTotalMetadata)

    const headers = r.data[0]
    const outData = r.data.slice(1).map((row, index) => {
        // console.log('headers', headers)
        // console.log('seriesId', estimateTotalMetadata[0].seriesId)
        const estimateTotalHeader = headers.indexOf(estimateTotalMetadata[0].seriesId)
        const estimateTotal = row[estimateTotalHeader]
        const outRecord = {}

        // console.log('row', row)


        const data = headers.map((header, index) => {
            const outRecordObj = {}
            outRecordObj[header] = row[index]

            const filteredMetadata = acsSeries.filter((i) => i.seriesId == header)

            // const filteredMetadata = seriesMetadata.filter((i)=>i.seriesId == header)

            if (filteredMetadata.length > 0) {
                Object.keys(filteredMetadata[0]).map((key) => {
                    if (key != 'label') {
                        outRecordObj[key] = filteredMetadata[0][key]
                    } else if (header != 'B01003_001E') {
                        outRecordObj[key] = filteredMetadata[0][key].split('!!')
                        outRecordObj['displayLabel'] = filteredMetadata[0][key].split('!!').join(' ')
                    } else {
                        // console.log('**here')
                        outRecordObj[key] = ['Statewide Population']
                        outRecordObj['displayLabel'] = 'Statewide Population'
                    }
                })
                outRecordObj['data'] = parseInt(outRecordObj[outRecordObj['seriesId']])
                outRecordObj['percent'] = Math.round((outRecordObj.data / estimateTotal) * 100000) / 1000
                delete outRecordObj[outRecordObj.seriesId]
                // console.log('outRecordObj', outRecordObj)
                return outRecordObj
            } else {
                outRecord[header] = outRecordObj[header]
            }
        })

        // console.log('data', data)
        // console.log('outRecord', outRecord)
        // return outRecord
        const nullsFilter = data.filter((i) => i != undefined)
        // console.log('nulls filter', nullsFilter)
        const sortedData = [nullsFilter[0], nullsFilter.filter((i)=>i.displayLabel == 'Estimate Total:')[0], ...nullsFilter.filter((i)=>!['Estimate Total:', 'Statewide Population'].includes(i.displayLabel)).sort((a,b) => {
            if ((a.label.length - b.label.length) < 0) {
                // console.log(a.label, 'shorter than', b.label)
                return -1
            } else if (a.label[2] && a.label[2].includes('Under')) {
                // console.log('a contains under', a.label)
                return -1
            } else if (b.label[2] && b.label[2].includes('Under')) {
                // console.log('b contains under', b.label)
                return 1
            } else if (a.label[2] && b.label[2]) {
                return parseFloat(a.label[2].split(' ')[0]) - parseFloat(b.label[2].split(' ')[0])
            }
        })]
        // console.log('sorted data', sortedData)
        outRecord['data'] = sortedData
        // outRecord['data'] = data.filter((i) => i != undefined)
        // outRecord['data'] = data
        // console.log('outRecord', outRecord)
        return outRecord
    })

    // const out = {'description': seriesMetadata.concept, 'data': outData}
    // const out = {'data': outData}
    // console.log('out', out)

    // return outData.filter((i)=>i.data.label)
    console.log('outData', outData)
    setSeriesId(outData[0].data[outData[0].data.length - 1].seriesId)
    setMapData(outData)
    return outData
}

const Popup = ({ data }) => (
    <div style={{ maxWidth: '200px' }}>
        {Object.keys(data).filter((i) => i != 'label').map((key, index) => {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'right', backgroundColor: (index % 2 == 0) ? 'rgba(102, 102, 102, 0.08)' : 'white', padding: '3px' }}>
                    <div style={{ fontWeight: 'bold' }}>{key}:</div>
                    <div>{data[key]}</div>
                </div>
            )
        })}
        {/* <div style={{fontSize: '1.2em'}}>
            <b>State: </b>{data.name}
        </div>
        <div style={{borderBottom: '2px solid black'}} />
        <div style={{display:'flex', justifyContent: 'space-between', textAlign: 'right', backgroundColor: 'rgba(102, 102, 102, 0.08)', padding: '3px'}}>
            <div style={{fontWeight: 'bold'}}>Description:</div>
            <div>{data.description}</div>
        </div>
        <div style={{display:'flex', justifyContent: 'space-between', textAlign: 'right', padding: '3px'}}>
            <div style={{fontWeight: 'bold'}}>Data:</div>
            <div>{data.data.toLocaleString()}</div>
        </div>
        <div style={{display:'flex', justifyContent: 'space-between', textAlign: 'right', padding: '3px', backgroundColor: 'rgba(102, 102, 102, 0.08)'}}>
            <div style={{fontWeight: 'bold'}}>TotalPop:</div>
            <div>{data.totalPop.toLocaleString()}</div>
        </div>
        <div style={{display:'flex', justifyContent: 'space-between', textAlign: 'right', padding: '3px'}}>
            <div style={{fontWeight: 'bold'}}>Per 100,000 persons:</div>
            <div>{data.perCapita.toLocaleString()}</div>
        </div>
        <div style={{display:'flex', justifyContent: 'space-between', textAlign: 'right', padding: '3px', backgroundColor: 'rgba(102, 102, 102, 0.08)'}}>
            <div style={{fontWeight: 'bold'}}>Percent:</div>
            <div>{data.percent.toLocaleString()}</div>
        </div> */}
    </div>
)


const updateMap = async (map, mapData, concept, seriesId, reverseStyle, setIsLoading, perCapita) => {
    setIsLoading(true)

    if (!map.current) {
        setIsLoading(false)
        return
    }

    const sourceData = statesGeojson.features.map((state) => {
        const stateRecord = mapData.filter((i) => i.NAME == state.properties.name)[0].data.filter((i) => i.seriesId == seriesId)[0]
        Object.keys(stateRecord).map((key) => {
            if (key != 'name') {
                state.properties[key] = stateRecord[key]
            }
        })
        state.properties.data = parseInt(state.properties.data)
        // state.properties.percent = Math.floor(Math.random() * 100) 

        // console.log('state', state)
        return state
    })

    const percentArr = sourceData.map((i) => i.properties.percent).filter((i) => !isNaN(i))
    const valueArr = sourceData.map((i) => i.properties.data).filter((i) => !isNaN(i))

    const maxValue = perCapita ? Math.max(...percentArr) : Math.max(...valueArr)
    const minValue = perCapita ? Math.min(...percentArr) : Math.min(...valueArr)
    console.log('maxValue', maxValue)
    console.log('minValue', minValue)


    map.current.on('load', () => {
        if (!map.current.getSource('states')) {
            console.log('not get source')

            map.current.addSource('states', {
                type: 'geojson',
                data: { "type": "FeatureCollection", "features": sourceData }
            })

            map.current.addLayer({
                id: 'states',
                type: 'fill',
                source: 'states',
                layout: {
                },
                paint: {
                    // 'fill-color': '#0080ff', // blue color fill
                    'fill-color': [
                        'case',
                        ['==', ['get', 'percent'], null],
                        '#8c8c8c',
                        ['interpolate', ['linear'], ['get', perCapita ? 'percent' : 'data'], minValue, !reverseStyle ? '#00e007' : '#e00000', maxValue, !reverseStyle ? '#e00000' : '#00e007'],
                    ],
                    'fill-opacity': 0.5
                }
            })
            map.current.addLayer({
                'id': 'outline',
                'type': 'line',
                'source': 'states',
                'layout': {},
                'paint': {
                    'line-color': '#000',
                    'line-width': 3
                }
            });
        }
        setIsLoading(false)
    })

    if (map.current.loaded && map.current.getSource('states')) {
        console.log('get source true')
        map.current.getSource('states').setData(
            {
                'type': 'FeatureCollection',
                'features': sourceData
            }
        )

        // map.current.setPaintProperty('states', 'fill-color', !reverseStyle ? ['interpolate', ['linear'], ['get', 'percent'], minValue, '#00e007', maxValue, '#e00000'] : ['interpolate', ['linear'], ['get', 'percent'], minValue, '#e00000', maxValue, '#00e007'])
        map.current.setPaintProperty('states', 'fill-color', [
            'case',
            ['==', ['get', 'percent'], null],
            '#8c8c8c',
            ['interpolate', ['linear'], ['get', perCapita ? 'percent' : 'data'], minValue, !reverseStyle ? '#00e007' : '#e00000', maxValue, !reverseStyle ? '#e00000' : '#00e007'],
        ])
    } else if (map.current.loaded && !map.current.getSource('states')) {
        console.log('not get source2')
        map.current.addSource('states', {
            type: 'geojson',
            data: { "type": "FeatureCollection", "features": sourceData }
        })

        map.current.addLayer({
            id: 'states',
            type: 'fill',
            source: 'states',
            layout: {
            },
            paint: {
                // 'fill-color': '#0080ff', // blue color fill
                'fill-color': [
                    'case',
                    ['==', ['get', 'percent'], null],
                    '#8c8c8c',
                    ['interpolate', ['linear'], ['get', perCapita ? 'percent' : 'data'], minValue, !reverseStyle ? '#00e007' : '#e00000', maxValue, !reverseStyle ? '#e00000' : '#00e007'],
                ],
                'fill-opacity': 0.5
            }
        })
        map.current.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'states',
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 3
            }
        });
    }
    setIsLoading(false)
}

export const Map = () => {
    // const [options, setOptions] = useState(fetchCensusSeries())
    const mapContainer = useRef(null)
    const popUpRef = useRef(new mapboxgl.Popup({ offset: 15, maxWidth: 'none' }))
    const map = useRef(null)
    const [lng, setLng] = useState(-97)
    const [lat, setLat] = useState(38)
    const [zoom, setZoom] = useState(3.5)
    const [seriesId, setSeriesId] = useState('B17002_013E')
    const [seriesOptions, setSeriesOptions] = useState(acsSeries.filter((i) => i.concept == 'Ratio of Income to Poverty Level in the Past 12 Months').sort((a, b) => b.label - a.label))
    const [concept, setConcept] = useState('Ratio of Income to Poverty Level in the Past 12 Months')
    const [isLoading, setIsLoading] = useState(false)
    const [reverseStyle, setReverseStyle] = useState(true)
    const [mapData, setMapData] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const [options, setOptions] = useState([])
    const [perCapita, setPerCapita] = useState(true)
    const [tabNumber, setTabNumber] = useState(0)
    const [filterText, setFilterText] = useState('Ratio of Income to Poverty Level in the Past 12 Months')
    const [popOpen, setPopOpen] = useState(false)
    const chartComponent = useRef({})

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            // style: 'mapbox://styles/mapbox/streets-v12',
            style: 'mapbox://styles/taylorjboone/cln7q2zfh078h01qbgus6d7e5',
            center: [lng, lat],
            zoom: zoom
        })
    })

    const changeTab = (event, newTab) => {
        setTabNumber(newTab)
        console.log('map', map.current)
        console.log('mapContainer', mapContainer.current)
    }

    useEffect(() => {
        console.log('concept', concept)
        updateMap(map, mapData, concept, seriesId, reverseStyle, setIsLoading, perCapita).then(() => setIsLoading(false))
    }, [reverseStyle, seriesId, mapData, perCapita])

    useEffect(() => {
        fetchCensusSeries(concept, setMapData, setSeriesId)
    }, [concept])

    useEffect(() => {
        if (!map.current) return;
        map.current.on('moveend', () => {
            setLng(map.current.getCenter().lng.toFixed(4))
            setLat(map.current.getCenter().lat.toFixed(4))
            setZoom(map.current.getZoom().toFixed(2))
        })


        map.current.on('click', 'states', (e) => {
            console.log('properties', e.features[0].properties)
            setSelectedState(e.features[0].properties.name)
            const popupNode = document.createElement('div')
            createRoot(popupNode).render(
                <Popup
                    data={e.features[0].properties}
                />
            )

            const coordinates = e.features[0].geometry.coordinates.slice();

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            popUpRef.current
                .setLngLat(e.lngLat)
                .setDOMContent(popupNode)
                .addTo(map.current)
        })

        map.current.on('mouseenter', 'states', () => {
            map.current.getCanvas().style.cursor = 'pointer';
        })
        map.current.on('mouseleave', 'states', () => {
            map.current.getCanvas().style.cursor = 'grab';
            // popup.remove();
        });

    })

    useEffect(() => {
        console.log('mapData', mapData)
        if (mapData.length > 0 && selectedState != '') {
            updateCharts(mapData, selectedState, setOptions)
        }
    }, [mapData, selectedState])

    useEffect(()=>{
        console.log('options', options)
    }, [options])

    useEffect(()=>{
        console.log('selected state*************', selectedState)
    }, [selectedState])

    useEffect(()=>{
        console.log('filterText', filterText)
    }, [filterText])


    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{flexGrow: 1, alignItems: 'flex-end'}}>
                    <Stack direction='column' spacing={0} style={{color: 'white', alignItems: 'center'}}>
                        <Typography>Reverse Style</Typography>
                        <Switch
                            size='small'
                            color='success'
                            checked={reverseStyle}
                            onChange={() => setReverseStyle(!reverseStyle)}
                        />
                    </Stack>
                </div>
                {/* <CustomTextField
                    value={filterText}
                    id='filterSearch'
                    size='small'
                    sx={{ marginBottom: '1px', marginTop: '2px', width: '30vw' }}
                    onFocus={()=>{
                        setPopOpen(true)
                    }}
                    // onBlur={()=>{
                    //     setPopOpen(false)
                    // }}
                    onChange={(event)=>{
                        // setAnchorEl(anchorEl ? null : event.currentTarget)
                        setFilterText(event.target.value)
                    }}
                />
                <CloseIcon 
                    onClick={() => {
                        setFilterText('')
                        setPopOpen(false)
                    }}
                    htmlColor='white'
                    sx={{marginLeft: '-40px', zIndex: 2}}
                /> */}

                {/* <Popper open={popOpen} anchorEl={document.getElementById('filterSearch')}>
                    <Box sx={{ bgcolor: 'background.paper', width: '30vw', maxHeight: 'calc(100vh - 150px)', overflow: 'auto' }}>
                    {uniqueConcepts.filter((i) => !filterText.split(' ').map((j)=> i.toLowerCase().includes(j.toLowerCase())).includes(false)).filter((i) => i.length < 100).map((option, index) => {
                        return <Typography
                            style={{padding: '5px 10px', backgroundColor: (index % 2 == 0) ? 'white' : 'rgba(102, 102, 102, 0.08)'}}
                            onClick={()=>{
                                setPopOpen(false)
                                setConcept(option)
                                setFilterText(option)
                            }}
                        >
                            {option}
                        </Typography>
                    })}
                    </Box>
                </Popper> */}
                <CustomAutocomplete
                    // options={acsSeries.slice(0,1000)}
                    // getOptionLabel={(option) => `${option.concept} - ${option.label}`}
                    options={uniqueConcepts.filter((i) => i.length < 100)}
                    sx={{ marginBottom: '1px', marginTop: '2px', width: '30vw' }}
                    popupIcon={<ArrowDropDown htmlColor="white"/>}
                    PopperComponent={(props) => <Popper {...props} style={{ width: '30vw' }}/>}
                    renderInput={(params) => <TextField {...params} label='Census Series' size='small' />}
                    defaultValue={'Ratio of Income to Poverty Level in the Past 12 Months'}
                    onChange={(event, value) => {
                        if (value && value != concept) {
                            setIsLoading(true)
                            console.log('value', value)
                            setConcept(value)
                            // setSeriesId(value.seriesId)

                            // const filteredSeries = acsSeries.filter((i) => i.concept == value).sort((a, b) => b.label - a.label)
                            // console.log('filteredSeries', filteredSeries)
                            // setSeriesOptions(filteredSeries)
                        }
                    }}
                />
                <Stack direction='column' spacing={0} alignItems='center' style={{ width: '8vw', marginLeft: '25px', marginLeft: 'auto', color: 'white', flexGrow: 1}}>
                    <Typography align='center'>Percent of Estimate</Typography>
                    <Switch
                        size='small'
                        color='success'
                        checked={perCapita}
                        onChange={() => setPerCapita(!perCapita)}
                    />
                </Stack>
            </div>
            
            <div style={{ display: 'flex' }}>
                <PanelGroup direction='horizontal'>
                    <Panel
                        defaultSize={20}
                    >
                    <Paper style={{ width: '100%', overflow: 'auto', height: 'calc(100vh - 150px)' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }} >
                            <h2
                                style={{ margin: '15px' }}
                            >
                                Subseries
                            </h2>
                        </div>

                        {/* {mapData.length > 0 ? mapData[0].data.sort((a, b) => sortLabelText(a,b)).sort((a, b) => a.label.length - b.label.length).map((i, index) => { */}
                        {mapData.length > 0 ? mapData[0].data.map((i, index) => {
                            return (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            backgroundColor: (index % 2 == 0) ? '' : 'rgba(102, 102, 102, 0.08)',
                                        }}

                                    >
                                        <Typography style={{ width: '15vw' }}>
                                            {i.label.join(' ')}
                                        </Typography>
                                        {(seriesId == i.seriesId) ? (
                                            <RadioButtonCheckedIcon />
                                        ) : (
                                            <RadioButtonUncheckedIcon
                                                onClick={() => {
                                                    setSeriesId(i.seriesId)
                                                }}
                                            />
                                        )}
                                    </div>
                                </>
                            )
                        }) : <></>}
                    </Paper>
                </Panel>
                <ResizeHandle />
                {(isLoading) ? (
                    // <div style={{ backgroundColor: 'rgba(102, 102, 102, 0.4)', position: 'fixed', height: 'calc(100vh - 130px)', width: 'calc(78vw - 10px)', zIndex: 2, marginLeft: '22vw' }}>
                    //     <div style={{ marginTop: '12%' }}>
                    //         <LoadingCircle />
                    //     </div>
                    // </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', zIndex: 2, height: '50vh', width: '100vw'}}>
                        <LoadingCircle />
                    </div>
                ) : <></>}
                <Panel
                    defaultSize={60}
                    onResize={()=>{
                        if (map.current) map.current.resize()
                    }}
                >
                    <Paper
                        style={{
                            height: 'calc(100vh - 150px)',
                            // width: 'calc(65vw - 10px)',
                            width: '101%',
                        }}
                    >
                        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabNumber} onChange={changeTab} aria-label="basic tabs example">
                                    <Tab label="Map" {...a11yProps(0)} style={{ fontWeight: 'bolder' }} />
                                    <Tab label="Table" {...a11yProps(1)} style={{ fontWeight: 'bolder' }} />
                                </Tabs>
                            </Box>
                        <CustomTabPanel value={tabNumber} index={0}> */}
                        <div ref={mapContainer} className='map-container' >
                            <div className='sidebar'>
                                Longitude: {lng} | Latitiude: {lat} | Zoom: {zoom}
                            </div>
                        </div>
                        
                        {/* </CustomTabPanel>
                        <CustomTabPanel value={tabNumber} index={1}>
                            <h3>Test</h3>
                        </CustomTabPanel> */}
                        
                    </Paper>
                </Panel>
                <ResizeHandle />
                <Panel
                    defaultSize={20}
                    onResize={() => {
                        const chart = chartComponent.current?.chart;
                        if (chart) chart.reflow(false);
                    }}
                >
                    <Paper style={{ width: '100%', overflow: 'auto', height: 'calc(100vh - 150px)' }}>

                        {selectedState != '' ? <>
                                <h2 style={{margin: '15px'}}>{selectedState}</h2>
                                {options.length > 0 ? (
                                    options.map((option) => {
                                    let tmp = JSON.parse(JSON.stringify(option));
                                    console.log('tmp', tmp)
                                    return (
                                            <div style={{ width: '90%', height: '90%', minHeight: '150px', minWidth: '150px', margin: '1vw', marginLeft: 0}}>
                                                <HighchartsReact
                                                    ref={chartComponent}
                                                    highcharts={Highcharts}
                                                    options={tmp}
                                                    containerProps={{ style: { height: '100%', width: '100%', margin: 0 } }}
                                                />
                                            </div>
                                    )
                                })) : <></>}
                            </> : <>
                                <h2 style={{margin: '15px'}}>Select a State</h2>
                            </>
                        }
                        
                    </Paper>
                </Panel>
                </PanelGroup>
            </div>
        </div>
    )
}