import axios from "axios"
import { useEffect, useRef, useState, useCallback } from "react"
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import tree from 'highcharts/modules/treemap';
import heatmap from 'highcharts/modules/heatmap'
import { CustomAutocomplete } from "../components/CustomAutocomplete";
import { Chip, Paper, TextField, Tooltip, Typography } from "@mui/material";
import LoadingCircle from "../components/LoadingCircle";
import Popup from "../components/Popup";
import { committees } from "../consts/campaignCommittees";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

// heatmap(Highcharts)
tree(Highcharts)

const fetchData = async (setData, setLoading, filters) => {
    setLoading(true)
    const request = await axios.post('/individual_contributions', {
        committees: filters
    })

    console.log('request data', request.data)
    setData(request.data)
}


const updateCharts = (data, setOptions, setLoading, chartRef, setOpen) => {
    setLoading(true)
    // const total = data.map((i)=>i.amount).reduce((a,b) => a+b)
    // const total = data.total

    // const seriesData = (filters.length > 0) ? data.results.filter((i)=> filters.some(v => i.id.includes(v.name.toUpperCase()) || i.id.includes(` ${v.abbreviation} `) || i.id.slice(0,3) == `${v.abbreviation} `)).slice(0,100).map((record) => {
    const seriesData = []
    data.slice(0,500).map((record) => {
        // return { name: `${record['CMTE_NM']}<br><span style='color:black;font-size: 14px'>${record['class']}</span>`, value: record['TRANSACTION_AMT'], colorValue: .9}
        // return { name: record['class'], parent: record['CMTE_NM'], value: record['TRANSACTION_AMT'], colorValue: .9}
        if (seriesData.filter((i) => i.name == record['CMTE_NM']).length == 0) {
            const seriesColor = (record['CMTE_PTY_AFFILIATION'] == 'Democratic Party') ? '#076DB0' :
                (record['CMTE_PTY_AFFILIATION'] == 'Republican Party') ? '#BE1E2D' :
                '#00bf33'
            seriesData.push({name: record['CMTE_NM'], color: seriesColor, id: record['CMTE_NM'], drilldown: record['CMTE_NM']})
        }
        seriesData.push({ name: record['class'], parent: record['CMTE_NM'], value: record['TRANSACTION_AMT']})
    })

    console.log('seriesData', seriesData)

    const options = {
        tooltip: {
            formatter() {
                var pointValue
                if (this.point.value > 1000000) {
                    pointValue = (this.point.value / 1000000).toFixed(1) + 'M'
                } else {
                    pointValue = this.point.value.toLocaleString()
                }
                return `${this.point.name}<br>$${pointValue}`
            },
        },
        series: [{
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            clip: false,
            data: seriesData,
            borderColor: '#a3a3a3',
            levels: [{
                level: 1,
                borderColor: '#4f4f4f',
                borderRadius: 6,
                borderWidth: 10,
                layoutAlgorithm: 'squarified',
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    verticalAlign: 'top',
                    style: {
                        fontSize: '1.3em',
                        fontWeight: 'bold',
                        color: 'white'
                    }
                }
            }],
        }],
        title: {
            text: undefined
        },
        // title: {
        //     text: 'US Campaign Contributions',
        //     style: {
        //         color: 'white',
        //         fontSize: '2em'
        //     }
        // },
        // subtitle: {
        //     text: 'by Committee and SOC Job Classification, 2021-2022',
        //     style: {
        //         color: 'white',
        //         fontSize: '1.1em'
        //     }
        // },
        drilldown: {
            // series: [{id: 'test', value: 1}]
            activeDataLabelStyle: {
                color: 'white',
                textDecoration: 'none'
            },
            series: []
        },
        chart: {
            backgroundColor: '#4f4f4f',
            exporting: {
                buttons: {
                    contextButton: {
                        symbolFill: '#707070'
                    }
                }
            },
            events: {
                drilldown: async (e)=>{
                    const chart = Highcharts.charts[Highcharts.charts.length - 1]
                    console.log('Highcharts obj', Highcharts.charts)
                    chart.showLoading('Loading data from server...')
                    // console.log('chart', chart)
                    // console.log('event', e)
                    const data = await axios.post('/individual_contributions_by_title', {
                        committee: e.point.options.name
                    })

                    chart.addSingleSeriesAsDrilldown(e.point, {
                        type: 'treemap',
                        layoutAlgorithm: 'squarified',
                        clip: false,
                        name: e.point.options.name,
                        data: data.data.map((i) => {
                            i.name = i['title']
                            i.value = i['TRANSACTION_AMT']
                            return i
                        })
                    })
                    chart.applyDrilldown()
                    chart.hideLoading()
                }
            }
        },
        plotOptions: {
            series: {
                // events: {
                //     click: async (e)=>{
                //         const data = await axios.post('/individual_contributions_by_title', {
                //             committee: e.point.options.parent
                //         })
                //         console.log('event', e.point.options)
                //         console.log('committee data', data.data)
                //         chartRef.addSingleSeriesAsDrilldown(e.point, {
                //             name: e.point.options.name,
                //             color: 'blue',
                //             data: data
                //         })
                //         chartRef.applyDrilldown()
                //     }
                // },
                dataLabels: {
                    enabled: true,
                    // format: '{point.name}<br>$ {point.value:.,2f}',
                    formatter() {
                        var pointValue
                        if (this.point.value > 1000000) {
                            pointValue = (this.point.value / 1000000).toFixed(1) + 'M'
                        } else {
                            pointValue = this.point.value.toLocaleString()
                        }
                        return `${this.point.name}<br>$${pointValue}`
                    },
                    style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '1.15em',
                        textOutline: ''
                    }
                }
            },
        },
    }


    console.log('options', options)
    setOptions(options)
    setLoading(false)
}

export const CampaignContributions = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const chartRef = useRef(null)
    const [open, setOpen] = useState(true)
    const [animated, setAnimated] = useState(false)
    const [options, setOptions] = useState({
        series: [],
        navigation: {
            buttonOptions: {
                // symbolFill: 'white',
                symbolStroke: 'white',
                theme: {
                    fill: '#707070',
                    // stroke: 'white'
                }
            }
        },
        drilldown: {
            series: []
        },
        title: {
            text: undefined
        },
        // title: {
        //     text: 'US Campaign Contributions',
        //     style: {
        //         color: 'white',
        //         fontSize: '2em'
        //     }
        // },
        // subtitle: {
        //     text: 'by Committee and SOC Job Classification, 2021-2022',
        //     style: {
        //         color: 'white',
        //         fontSize: '1.1em'
        //     }
        // },
        chart: {
            backgroundColor: '#4f4f4f',
        },
        plotOptions: {
            series: {
                // events: {
                //     afterAnimate: () => {
                //         setOpen(true)
                //     }
                // },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.3em',
                        textOutline: ''
                    }
                }
            },
            
        },
    })
    const [filters, setFilters] = useState([])
    const [titleRefHeight, setTitleRefHeight] = useState(0)
    const titleRef = useCallback(node => {
        if (node !== null) {
            const resizeObserver = new ResizeObserver(() => {
                setTitleRefHeight(node.getBoundingClientRect().height);
            })
            resizeObserver.observe(node)
        }
      }, []);

    useEffect(()=>{
        fetchData(setData, setLoading, filters)
    }, [filters])

    useEffect(()=>{
        if (data && data.length > 0) {
            updateCharts(data, setOptions, setLoading, chartRef, setOpen)
        }
    }, [data])

    useEffect(()=>{
        console.log('popup open', open)
    }, [open])

    useEffect(()=>{
        console.log('title ref height', titleRefHeight)
    }, [titleRefHeight])


    return <>
        <div style={{height: 'calc(100vh - 100px', display: 'flex', flexDirection: 'column'}}>
            {/* <div style={{ marginBottom: '1px', marginTop: '10px', width: '40vw', zIndex: 2, position: 'absolute' }}> */}
            <div ref={titleRef} style={{ marginBottom: '1px', marginTop: '10px' }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: '25vw', alignItems: 'center', justifyContent: 'space-between'}}>
                        <CustomAutocomplete
                            sx={{ width: '23vw' }}
                            popupIcon={<ArrowDropDown htmlColor="white"/>}
                            options={committees.filter((i)=>!filters.includes(i))}
                            // getOptionLabel={(state) => state.name}
                            renderInput={(params) => <TextField {...params} label='Search Committee' size='small' />}
                            onChange={(event, value)=>{
                                if (value && !filters.includes(value)) {
                                    setFilters([...filters, value])
                                }
                            }}
                        />
                        <Tooltip
                            title='Clear all filters'
                        >
                            <ClearIcon
                                style={{color: 'white'}}
                                onClick={()=>{
                                    if (filters.length > 0) {
                                        setFilters([])
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div style={{width: '50vw', color: 'white'}}>
                        <Typography variant='h5' style={{textAlign: 'center'}}>US Campaign Contributions</Typography>
                        <Typography variant='subtitle1' style={{textAlign: 'center'}}>by Committee and SOC Job Classification, 2023-2024</Typography>
                    </div>
                    <div style={{width: '25vw'}} />
                    
                    
                    

                </div>
                
                <div>
                    {filters.map((filter) => {
                        const chipLength = 12
                        return <>
                            <Tooltip
                                title={filter}
                            >
                                <Chip
                                    label={(filter.length > chipLength) ? filter.slice(0, chipLength) + '..' : filter}
                                    sx={{ color: 'white'}}
                                    size="small"
                                    variant="outlined"
                                    onDelete={()=>{
                                        setFilters(filters.filter((i) => i!=filter))
                                    }}
                                />
                            </Tooltip>
                            
                        </>
                    })}
                </div>
            </div>
            {(loading) ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', zIndex: 2, height: '50vh', width: '100vw'}}>
                    <LoadingCircle />
                </div>
            ) : <></>}
            {/* <Tooltip
                open={tooltipBool}
                title={(tooltipBool) ? 'test' : ''}
                style={{position: 'fixed', margin: '5px 15px', zIndex: 2, height: '50px', width: '175px', padding: '10px'}}
            >
                <Paper>
                    <Typography>
                        Click a committee name to drilldown to job titles
                    </Typography>
                    <ArrowDropDownIcon
                        fontSize='large'
                        style={{color: 'white'}}
                    />
                </Paper>
            </Tooltip> */}
            <Popup open={open} setOpen={setOpen} />
            <div style={{height: `calc(100vh - ${100 + titleRefHeight}px)`}}>
                <HighchartsReact 
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                    containerProps={{style: {height: '100%', width: '100%', zIndex: 1}}}
                />
            </div>
            
            
        </div>
        
    </>
}
