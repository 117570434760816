import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InlineLink, NavLink } from '../../components/Navbar/NavbarElements';
import capitol from '../../assets/transparent-capitol.png';

export const Happiness = () => {
    const [data, setData] = useState(null);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'Left',
                alignItems: 'Left',
                height: '80vh',
                color: 'white',
                margin: '0 1vw'
            }}
        >
            <div style={{margin: '10vh auto', textShadow: 'black 2px 2px', fontSize: '1.5em', zIndex: 1}}>
                <center><h1>Happiness</h1></center>
                <p>Happiness is when</p>
                <p>what you think,</p>
                <p>what you say,</p>
                <p>and <InlineLink to='/' >what you do</InlineLink></p>
                <p>are in harmony.</p>
                <p>-Mahatma Gandhi</p>
            </div>
            <img src={capitol} alt='Capitol' style={{position: 'fixed', width: '95vw', zIndex: 0}}/>
        </div>
    )
}