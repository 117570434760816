import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Field, Form, Formik } from 'formik';


export const Login = (props) => {
    const {
        setToken = ()=>{},
        loggedIn = false,
        userData = {}
    } = props

    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    // const [userData, setUserData] = useState({})

    const handleSubmit = () => {
        const postBody = {username: user, password: password}
        axios.post('/auth', postBody, {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then((response) => {
            console.log('response', response.data)
            setToken(response.data.status)
            if (!response.data.status) {
                alert('Invalid username/password combination.')
                setPassword('')
            }
        })

    }


    if (loggedIn) {
        console.log('userData', userData)

        return ( (userData != {}) ?
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <h1 style={{color: 'white'}}>Welcome, {userData.firstName}</h1>
                <TableContainer component={(props) => <Paper {...props} elevation={3} />} style={{maxWidth: '30vw'}}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>User:</TableCell>
                                <TableCell>{userData.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Email:</TableCell>
                                <TableCell>{userData.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Role:</TableCell>
                                <TableCell>{userData.role}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            : <></>
        )
    }

    return ( 
        <Paper style={{width: '15vw', margin: 'auto', padding: '1%', marginTop: '5vh'}}>
            <Formik
            initialValues={{user: '', password: ''}}
            >
                <Form>
                    <h1 style={{}}>Log In</h1>
                    <p>Username</p>
                    <Field name='username' onChange={(e) => setUser(e.target.value)} value={user}/>
                    <p>Password</p>
                    <Field name='password' type='password' onChange={(e) => setPassword(e.target.value)} value={password}/>
                    <div style={{justifyContent: 'right', display: 'flex'}} >
                        <Button onClick={handleSubmit} onKeyDown={(e)=>{
                            if (e.keyCode==13) {
                                handleSubmit()
                            }
                        }} type="submit" variant="contained" color="success" style={{marginRight: '1%', marginTop: '5%'}}>Login</Button>
                    </div>

            </Form>
            </Formik>
        </Paper>
    )
}