import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Autocomplete, Button, Paper, TextField, IconButton, Switch, Stack, Typography, List, ListItem, Accordion, AccordionSummary } from '@mui/material';
import LineChart from '../../components/LineChart';
import LoadingCircle from '../../components/LoadingCircle';
import { Field, Form, Formik } from 'formik';
import { ExternalLink, InlineLink, Input } from '../../components/Navbar/NavbarElements';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Configuration, OpenAIApi } from 'openai';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import topSeries from '../../consts/currentEmployment.json';


const configuration = new Configuration({
    apiKey: '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'
})
const openai = new OpenAIApi(configuration)

const topSeries = {
    'LNS11000000': 'Civilian Labor Force (Seasonally Adjusted)',
    'LNS12000000': 'Civilian Employment (Seasonally Adjusted)',
    'LNS13000000': 'Civilian Unemployment (Seasonally Adjusted)',
    'LNS14000000': 'Unemployment Rate (Seasonally Adjusted)',
    // 'CES0000000001': 'Total Nonfarm Employment - Seasonally Adjusted',
    'CES0500000002': 'Total Private Average Weekly Hours of All Employees - Seasonally Adjusted',
    'CES0500000007': 'Total Private Average Weekly Hours of Prod. and Nonsup. Employees - Seasonally Adjusted',
    'CES0500000003': 'Total Private Average Hourly Earnings of All Employees - Seasonally Adjusted',
    'CES0500000008': 'Total Private Average Hourly Earnings of Prod. and Nonsup. Employees - Seasonally Adjusted',
    // 'PRS85006092': 'Output Per Hour - Non-farm Business Productivity',
    // 'PRS85006112': 'Nonfarm Business Unit Labor Costs',
    // 'PRS85006152': 'Nonfarm Business Real Hourly Compensation',
    // 'MPU4910012': 'Private Nonfarm Business - Multifactor Productivity, annual index',
    'CUUR0000SA0': 'CPI for All Urban Consumers (CPI-U) 1982-84=100 (Unadjusted)',
    'CUUR0000AA0': 'CPI for All Urban Consumers (CPI-U) 1967=100 (Unadjusted)',
    'CWUR0000SA0': 'CPI for Urban Wage Earners and Clerical Workers (CPI-W) 1982-84=100 (Unadjusted)',
    'CUUR0000SA0L1E': 'CPI-U/Less Food and Energy (Unadjusted)',
    'CWUR0000SA0L1E': 'CPI-W/Less Food and Energy (Unadjusted)',
    'WPSFD4': 'PPI Final Demand (Seasonally Adjusted)',
    'WPUFD4': 'PPI Final Demand (Unadjusted)',
    'WPUFD49104': 'PPI Final Demand less foods and energy (Unadjusted)',
    'WPUFD49116': 'PPI Final Demand less foods, energy, and trade services (Unadjusted)',
    'WPUFD49207': 'PPI Finished Goods 1982=100 (Unadjusted)',
    'EIUIR': 'Imports - All Commodities',
    'EIUIQ': 'Exports - All Commodities',
    // 'CIU1010000000000A': 'Employment Cost Index (ECI) Civilian (Unadjusted)',
    // 'CIU2010000000000A': 'ECI Private (Unadjusted)',
    // 'CIU2020000000000A': 'ECI Private Wage and Salaries (Unadjusted)',
    'CUSR0000SA0': 'All items in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SA0L1E': 'All items less food and energy in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SAF1': 'Food in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SA0E': 'Energy in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SAM2': 'Medical care services in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SETA02': 'Used cars and trucks in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SAH1': 'Shelter in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SAA': 'Apparel in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SETB01': 'Gasoline (all types) in U.S. city average, all urban consumers, seasonally adjusted',
    'CUSR0000SAS4': 'Transportation services in U.S. city average, all urban consumers, seasonally',
    'CUUR0000SA0E': 'Energy in U.S. city average, all urban consumers, not seasonally adjusted',
    'CUUR0000SAM': 'Medical care in U.S. city average, all urban consumers, not seasonally adjusted',
    'CUUS0000SA0': 'All items in U.S. city average, all urban consumers, not seasonally adjusted'
}



const topSeriesInverse = {}
Object.keys(topSeries).map((seriesId) => {
    const key = topSeries[seriesId]
    topSeriesInverse[key] = seriesId
})

const laborOptionList = Object.keys(topSeries).map((key) => {
    return { 'seriesId': key, 'label': topSeries[key] }
})


function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

// function zipList(inputList) {
//     const zipDict = {}
//     inputList.map((i) => {
//         zipDict[i] = {'name':topSeries[i], data: []}
//     })
//     return zipDict
// }

function flatten(arr) {

    return arr.reduce(function (flat, toFlatten) {

        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);

    }, []);

}

function sortMonths(a, b) {
    if (a.month < b.month) {
        return -1;
    }
    if (a.month > b.month) {
        return 1;
    }
    return 0;
}


const Chart = (props) => {
    const {
        // inputSeries = [],
        inputSeries = [
            "CUSR0000SAH1",
            "CES0500000008",
            "CUSR0000SAF1",
            "CUSR0000SA0E"
        ],
        inputNormBool = true,
        userData = {},
        loggedIn = false
    } = props

    const [startYear, setStartYear] = useState(1984)
    const [endYear, setEndYear] = useState(2022)
    const [input, setInput] = useState({ name: '', label: '' })
    const [months, setMonths] = useState([])
    const [data, setData] = useState({})
    const [laborOptions, setLaborOptions] = useState(laborOptionList)
    const [isLoading, setIsLoading] = useState(false)
    const [blsSeries, setBlsSeries] = useState(inputSeries)
    const [chartSeries, setChartSeries] = useState([])
    const [normBool, setNormBool] = useState(inputNormBool)


    const setBlsSeriesByName = (obj) => {
        const seriesList = obj['seriesNames'].filter((i) => Object.keys(topSeriesInverse).includes(i)).map((name) => {
            return topSeriesInverse[name]
        })
        console.log('blsSeries', seriesList)
        setBlsSeries(seriesList)
    }

    const fetchBLS = async () => {
        // console.log('input series', inputSeries)

        // const newData2 = {...data}
        const newData2 = {}
        const requests = []

        const newMonths = []
        for (let i = startYear; i <= endYear; i++) {
            for (let j = 1; j < 13; j++) {
                newMonths.push(`${i}-${String(j).padStart(2, '0')}`)
            }
        }
        setMonths(newMonths)

        blsSeries.map((series) => {
            // if (!Object.keys(data).includes(topSeries[`${series}`])) {
            //     const newMonths = []
            //     for (let i = startYear; i <= endYear; i++) {
            //         for (let j = 1; j < 13; j++) {
            //             newMonths.push(`${i}-${String(j).padStart(2, '0')}`)
            //         }
            //     }
            //     console.log(`setting empty object for series: ${series}`)
            //     newData2[topSeries[`${series}`]] = newMonths.map((month) => { return { month: month, value: null } })
            // }

            newData2[topSeries[series]] = newMonths.map((month) => { return { month: month, value: null } })

            // console.log('data', topSeries[`${series}`], data)

            // Do this is there are null values in the data dict for this series
            if (newData2[topSeries[series]].filter((i) => i.value == null).length > 0) {
                // const requests = []

                // Runs in blocks of 20 years based on start/end years
                // Builds an array of requests to be executed in the Promise.all
                for (let i = 0; i < Math.ceil((parseInt(endYear) - parseInt(startYear)) / 20); i++) {
                    const requestStartYear = parseInt(startYear) + (20 * i)
                    const requestEndYear = parseInt(startYear) + 19 + (20 * i)
                    const bls_keys = ['f312d00353ca40d4aa8bc38f2fa98ccb', '61f24658b84e47acb3881e3b87a4aebb']
                    const bls_key = bls_keys[Math.floor(Math.random(2) * 2)]
                    const headers = {
                        'Content-type': 'application/json',
                    }
                    const params = {
                        'startyear': requestStartYear,
                        'endyear': requestEndYear
                    }
                    // console.log('request url', `https://api.bls.gov/publicAPI/v2/timeseries/data/${series}?registrationkey=${bls_key}`, params)
                    requests.push(
                        axios.get(`https://api.bls.gov/publicAPI/v2/timeseries/data/${series}?registrationkey=${bls_key}`, { headers: headers, params: params })
                    )
                }


                // Promise.all(requests)
                //     .then((i) => {
                //         const flat = flatten(i.map((response) => {
                //             const responseData = response.data.Results.series[0].data
                //             responseData.reverse()
                //             // responseData = responseData.map((i) => {
                //             //     return {...i, month: `${i.year}-${i.period.slice(-2)}`}
                //             // })
                //             // return responseData
                //             return responseData.map((i) => {
                //                 return { ...i, month: `${i.year}-${i.period.slice(-2)}` }
                //             })
                //         }))
                //         flat.sort(sortMonths)
                //         const firstValue = parseFloat(flat[0].value)
                //         // console.log('first Value', firstValue)
                //         const newData = flat.map((i) => {
                //             // console.log('percent', ((parseFloat(i.value) / firstValue) * 100))
                //             return { ...i, percent: ((parseFloat(i.value) / firstValue) * 100) }
                //             // return {...i, percent: Math.round((parseFloat(i.value) / firstValue) * 100)}
                //         })
                //         // console.log('flat', flat)
                //         // console.log('setting data', { ...data, [topSeries[`${series}`]]: newData.filter((i) => months.includes(i.month)) })
                //         newData2[topSeries[`${series}`]] = newData.filter((i) => months.includes(i.month))

                //         // setData({ ...data, [topSeries[`${series}`]]: newData.filter((i) => months.includes(i.month)) })
                //         setInput({ name: '', label: '' })
                //         const newOptions = laborOptions.filter((i) => (blsSeries.filter((j) => i.seriesId == j).length == 0))
                //         setLaborOptions(newOptions)
                //     })
            }


        })
        // console.log('requests', requests)

        Promise.all(requests)
            .then((i) => {
                const flat = flatten(i.map((response) => {
                    const responseData = response.data.Results.series[0].data
                    const seriesID = response.data.Results.series[0].seriesID
                    responseData.reverse()
                    // responseData = responseData.map((i) => {
                    //     return {...i, month: `${i.year}-${i.period.slice(-2)}`}
                    // })
                    // return responseData
                    return responseData.map((i) => {
                        return { ...i, month: `${i.year}-${i.period.slice(-2)}`, series: seriesID }
                    })
                }))
                flat.sort(sortMonths)
                const firstValue = {}
                blsSeries.map((series) => {
                    if (flat.filter((i) => i.series == series).length > 0) {
                        firstValue[series] = parseFloat(flat.filter((i) => i.series == series)[0].value)
                    }
                })
                // console.log('first Value', firstValue)
                const newData = flat.map((i) => {
                    // console.log('percent', ((parseFloat(i.value) / firstValue) * 100))
                    return { ...i, percent: ((parseFloat(i.value) / firstValue[i.series]) * 100) }
                    // return {...i, percent: Math.round((parseFloat(i.value) / firstValue) * 100)}
                })
                // console.log('setting data', { ...data, [topSeries[`${series}`]]: newData.filter((i) => months.includes(i.month)) })
                blsSeries.map((series) => {
                    // newData2[topSeries[series]] = newData.filter((i) => (newMonths.includes(i.month)) && (i.series == series))
                    newData2[topSeries[series]] = newMonths.map((month) => {
                        if (newData.filter((i) => (i.month == month) && (i.series == series)).length > 0) {
                            return newData.filter((i) => (i.month == month) && (i.series == series))[0]
                        } else {
                            return {month: month, value: null, percent: null}
                        }
                    })
                })
                console.log('newData2', newData2)
                // newData2[topSeries[`${series}`]] = newData.filter((i) => months.includes(i.month))

                // setData({ ...data, [topSeries[`${series}`]]: newData.filter((i) => months.includes(i.month)) })
                setInput({ name: '', label: '' })
                const newOptions = laborOptions.filter((i) => (blsSeries.filter((j) => i.seriesId == j).length == 0))
                setLaborOptions(newOptions)
                const chartprint = Object.keys(newData2).map((key) => {
                    if (normBool) {
                        return {
                            name: key,
                            data: newData2[key].map((i) => {
                                return {
                                    y: i.percent ? parseFloat(i.percent) : null,
                                    custom: {
                                        displayPercent: parseFloat(i.percent).toFixed(1),
                                        extraInformation: i.value
                                    }
                                }
    
                            }),
                            tooltip: {
                                pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.custom.extraInformation} - {point.custom.displayPercent}%</b><br/>'
                            }
                        }
                    } else {
                        return {
                            name: key,
                            data: newData2[key].map((i) => {
                                return {
                                    y: i.value ? parseFloat(i.value) : null,
                                    custom: {
                                        displayValue: parseFloat(i.value).toFixed(1),
                                    }
                                }
    
                            }),
                            tooltip: {
                                pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.custom.displayValue}</b><br/>'
                            }
                        }
                    }
                    
                })
                console.log('chartprint', chartprint)
                setChartSeries(chartprint)
                setData(newData2)

            })

    }



    // useEffect(() => {
    //     console.log('data', data)
    //     const chartprint = Object.keys(data).map((key) => {
    //         return {
    //             name: key,
    //             data: data[key].map((i) => {
    //                 return {
    //                     y: i.percent ? parseFloat(i.percent) : null,
    //                     custom: {
    //                         displayPercent: parseFloat(i.percent).toFixed(1),
    //                         extraInformation: i.value
    //                     }
    //                 }

    //             }),
    //             tooltip: {
    //                 pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.custom.extraInformation} - {point.custom.displayPercent}%</b><br/>'
    //             }
    //         }
    //     })
    //     console.log('chartprint', chartprint)
    //     setChartSeries(chartprint)
    // }, [data])


    // useEffect(() => {
    //     const newMonths = []
    //     for (let i = startYear; i <= endYear; i++) {
    //         for (let j = 1; j < 13; j++) {
    //             newMonths.push(`${i}-${String(j).padStart(2, '0')}`)
    //         }
    //     }
    //     // console.log('new months', newMonths)
    //     setMonths(newMonths)
    // }, [startYear, endYear])

    // useEffect(() => {
    //     console.log('data', data)
    // }, [data])

    // useEffect(() => {
    //     console.log('fetching bls', blsSeries)
    //     fetchBLS()
    // }, [months])

    useEffect(() => {
        console.log('data', data)
    }, [data])

    useEffect(()=>{
        fetchBLS()
    },[])

    useEffect(()=>{
        console.log('bls series', blsSeries)
        fetchBLS()
    },[blsSeries])

    // useEffect(() => {
    //     console.log('labor options', laborOptions)
    // }, [laborOptions])

    // useEffect(() => {
    //     console.log('data', data)
    //     console.log('months', months)
    //     console.log('test', Object.keys(data).map((key) => {
    //         return {'name':data[key].name, 'data':data[key].data}
    //         // return data[key].series
    //     }))
    // }, [months, data])

    return (
        <>
            {/* <Formik
            initialValues={{startYear: '', endYear: ''}}
        >
            <Form style={{margin: 'auto'}}>
                <Field name='startYear'label='Start' />
                <Field name='endYear' label='End' />
            </Form>
        </Formik> */}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '2%', alignItems: 'center', marginTop: '0.5vh' }}>
                <Stack direction='column' spacing={0} alignItems='center' style={{width: '8vw'}}>
                    <Typography style={{color: 'white'}}>Normalize</Typography>
                    <Switch
                        size='small'
                        color='success'
                        checked={normBool}
                        onChange={()=> setNormBool(!(normBool))}
                    />
                </Stack>
                <Input
                    // disabled={(Object.keys(data).length > 0)}
                    value={startYear}
                    type='number'
                    onChange={(e) => {
                        if (e.target.value < endYear) {
                            setStartYear(e.target.value)
                        } else {
                            setStartYear(parseInt(endYear) - 1)
                        }
                    }}
                    style={{ width: '10vw' }}
                    size='small'
                    sx={{ input: { textAlign: 'center' }, marginTop: '0.5vh'}}
                />
                <Input
                    // disabled={(Object.keys(data).length > 0)}
                    value={endYear}
                    type='number'
                    onChange={(e) => {
                        if (e.target.value > startYear) {
                            setEndYear(e.target.value)
                        } else {
                            setEndYear(parseInt(startYear) + 1)
                        }
                    }}
                    style={{ width: '10vw' }}
                    size='small'
                    sx={{ input: { textAlign: 'center' }, marginTop: '0.5vh'}}
                />
                <Button
                    onClick={() => fetchBLS()}
                    variant='contained'
                    color='success'
                    size='small'
                    sx={{ height: '4vh', width: '8vw', minHeight: '40px' }}
                >Fetch Data</Button>
            </div>

            <div style={{ margin: 'auto', height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                {(isLoading) ? (
                    <div style={{ backgroundColor: 'rgba(102, 102, 102, 0.4)', position: 'fixed', margin: 'auto', height: '80vh', width: 'calc(100vw - 10px)', zIndex: 2, marginTop: '1vh' }}>
                        <div style={{ marginTop: '12%' }}>
                            <LoadingCircle />
                        </div>
                    </div>
                ) : <></>}
                <>
                    {/* <div style={{ display: 'flex', height: '65vh', alignItems: 'center', marginTop: '3vh', marginLeft: '-4.5vw' }} > */}
                    <Paper style={{ display: 'flex', height: '80vh', alignItems: 'center', marginTop: '1vh', width: '100%', zIndex: 1}} >
                        <Paper style={{ overflow: 'auto', height: '100%', maxWidth: '13vw', width: '13vw', minWidth: '13vw' }}>
                            {/* Add Data Pane, left of chart */}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '1vw' }}>
                                <h2>Add Data</h2>
                            </div>
                            <FormGroup>

                                <Accordion
                                    style={{overflow: 'auto'}}
                                    defaultExpanded={true}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            BLS API
                                        </Typography>
                                    </AccordionSummary>
                                    <List>
                                        {Object.keys(topSeries).map((seriesId) => {
                                            return <ListItem>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    checked={blsSeries.includes(seriesId)}
                                                    label={topSeries[seriesId]}
                                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: 12 } }}
                                                    onChange={() => {
                                                        if (blsSeries.includes(seriesId)) {
                                                            setBlsSeries(blsSeries.filter((i) => i != seriesId))
                                                        } else {
                                                            setBlsSeries([...blsSeries, seriesId])
                                                        }
                                                    }}
                                                />
                                                </ListItem>
                                        })}
                                    </List>
                                </Accordion>
                                
{/* 
                                {Object.keys(topSeries).map((seriesId) => {
                                    return <FormControlLabel
                                            control={<Checkbox />}
                                            checked={blsSeries.includes(seriesId)}
                                            label={topSeries[seriesId]}
                                            sx={{ '& .MuiFormControlLabel-label': { fontSize: 12 } }}
                                            style={{ marginBottom: '3px' }}
                                            onChange={() => {
                                                if (blsSeries.includes(seriesId)) {
                                                    setBlsSeries(blsSeries.filter((i) => i != seriesId))
                                                } else {
                                                    setBlsSeries([...blsSeries, seriesId])
                                                }
                                            }}
                                        />
                                })} */}
                            </FormGroup>
                        </Paper>
                        {/* Smart prompt and chart container */}
                        <div style={{width: '80vw', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <TextField
                                    size='small' 
                                    variant='outlined'
                                    // disabled={!['admin', 'demo'].includes(userData.role)}
                                    // disabled={!loggedIn}
                                    sx={{width: '90%', marginTop: '1%'}} 
                                    // label={(['admin', 'demo'].includes(userData.role)) ? 'Smart Prompt' : 'Log In to use Smart Prompt'}
                                    // label={(loggedIn) ? 'Smart Prompt' : 'Log In to use Smart Prompt'}
                                    label='GPT-4 Smart Prompt'
                                    onKeyDown={async (event)=>{
                                        // console.log('input list', inputList)
                                        if (event.key == 'Enter' && event.target.value) {
                                            setIsLoading(true)
                                            const inputList = Object.keys(topSeriesInverse).join(', ')
                                            const chatCompletion = await openai.createChatCompletion({
                                                model: 'gpt-3.5-turbo',
                                                temperature: 0,
                                                messages: [
                                                    {role: 'system', content: 'Use this list as context:'},
                                                    {role: 'system', content: inputList},
                                                    {role: 'user', content: `from the above given list, select the items most relevant to ${event.target.value}`},
                                                    {role: 'system', content: 'Do not select more than 6 items'}
                                                ],
                                                functions: [
                                                    {
                                                        'name': 'setBlsSeriesByName',
                                                        'description': 'Convert a list of BLS series names to their series IDs',
                                                        'parameters': {
                                                            'type': 'object',
                                                            'properties': {
                                                                'seriesNames': {
                                                                    'type': 'array',
                                                                    'items': {
                                                                        'type': 'string'
                                                                    },
                                                                    'description': 'a list of BLS Series Names'
                                                                }
                                                            }
                                                        }
                                                    }
                                                ],
                                                function_call: {'name': 'setBlsSeriesByName'},
                                                max_tokens: 1000
                                            })
                                            // console.log('chat gpt', chatCompletion)
                                            // alert(chatCompletion['data']['choices'][0]['message'])
                                            const args = JSON.parse(chatCompletion['data']['choices'][0]['message']['function_call']['arguments'])
                                            console.log('args', args)
                                            setBlsSeriesByName(args)
                                            setIsLoading(false)

                                        }
                                        
                                    }}
                                />
                                <div style={{height: 'calc(99% - 40px)', width: '100%'}}>
                                    <LineChart
                                        title={''}
                                        xAxis={months}
                                        series={chartSeries}
                                    />
                                </div>                          
                        </div>
                        {/* Sources pane, right of chart */}
                        <Paper style={{ overflow: 'auto', height: '100%', maxWidth: '13vw', width: '13vw', minWidth: '13vw', paddingLeft: '1%' }}>
                            <h2>Sources</h2>
                            {blsSeries.map((i) => {
                                return (
                                    <div style={{ marginBottom: '3%' }}>
                                        <ExternalLink
                                            href={`https://data.bls.gov/timeseries/${i}`}
                                            target='_blank'
                                        >
                                            {topSeries[i]}
                                        </ExternalLink>
                                    </div>
                                )
                            })}

                        </Paper>

                    {/* </div> */}
                    </Paper>

                </>
            </div>
        </>
    )
}

export default Chart;