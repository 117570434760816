import { PanelResizeHandle } from "react-resizable-panels";

import styles from "./styles.module.css";

export default function ResizeHandle({
  className = "",
  id,
  orient = 'horizontal'
}) {
  return (
    <PanelResizeHandle
      className={[styles.ResizeHandleOuter, className].join(" ")}
      id={id}
    >
      <div className={styles.ResizeHandleInner}>
        <svg className={styles.Icon} viewBox="0 0 24 24">
          <path
            // fill="currentColor"
            fill="white"
            // d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
            // d="M 6 8 V 11 H 9 V 2 H 11 V 22 H 9 V 13 H 6 V 16 L 2 12 L 6 8 M 22 12 L 18 8 V 11 H 15 V 2 H 13 V 22 H 15 V 13 H 18 V 16 L 22 12 Z"
            d={(orient == 'horizontal') ? "M 6 8 V 11 H 9 V 2 H 11 V 22 H 9 V 13 H 6 V 16 L 2 12 L 6 8 M 22 12 L 18 8 V 11 H 15 V 2 H 13 V 22 H 15 V 13 H 18 V 16 L 22 12 Z" : "M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"}
          />
        </svg>
      </div>
    </PanelResizeHandle>
  );
}
