import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';


const bernie_id = 'P60007168'




// export default function DenseTable(props) {
//     const {
//         id = bernie_id
//     } = props;
//     const [data, setData] = React.useState(null);
//     const [isLoading, setLoading] = React.useState(true);

//     const fetchData = async (candidate_id) => {
//         await axios.get('/candidate/' + candidate_id).catch((errors) => console.log(errors)).then((response) => this.setState({ data: response.data}))
//     }
    
//     const componentDidMount = () => {

//     }
// }


export class DenseTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true
        }
    }
    
    componentDidMount() {
        this.fetchData(this.props.candidateId)
    }

    // async fetchData(candidate_id) {
    //     try {
    //         const data = await axios.get('/candidate/' + candidate_id);
    //         this.setState({ data: data.data, isLoading: false });
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async fetchData(candidate_id) {
        await axios.get('/candidate/' + candidate_id).catch((errors) => console.log(errors)).then((response) => this.setState({ data: response.data}))
    }

    render() {
        console.log('this.props', this.props)
        if (this.state.isLoading) {
            return (
                <div style={{marginTop: '5vh', maxHeight: '80vh'}}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                    <TableHead>
                        <TableRow>
                            {(this.state.data&&this.state.data.length>0) ? Object.keys(this.state.data[0]).map((data) => {
                                data = data.split('_')
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0].toUpperCase() + data[i].substr(1);
                                }
                                data = data.join(' ')
                                return <TableCell key={data}>{data}</TableCell>
                            }) 
                            : <></>
                        }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((row) => {
                            return (<TableRow>
                                {Object.values(row).map((i) => {
                                    if (typeof i === 'number' && (i > 2100 || (i < 1900 && i > 0))) {
                                        i = Math.round(i)
                                        i = '$' + i.toLocaleString()
                                    } else if (typeof i === 'number' && i < 0) {
                                        i = Math.round(i) * -1
                                        i = '-$' + i.toLocaleString()
                                    }
                                    return <TableCell>{i}</TableCell>
                                })}
                            </TableRow>)
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                </div>
            )
        }
    }

        
}


export default DenseTable = (props) => {
    const {
        data = [],
    } = props


    return (
        <div style={{marginTop: '5vh', maxHeight: '80vh'}}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                <TableHead>
                    <TableRow>
                        {(data&&data.length>0) ? Object.keys(data[0]).map((record) => {
                            record = record.split('_')
                            for (let i = 0; i < record.length; i++) {
                                record[i] = record[i][0].toUpperCase() + record[i].substr(1);
                            }
                            record = record.join(' ')
                            return <TableCell key={record}>{record}</TableCell>
                        }) 
                        : <></>
                    }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                        return (<TableRow>
                            {Object.values(row).map((i) => {
                                if (typeof i === 'number' && (i > 2100 || (i < 1900 && i > 0))) {
                                    i = Math.round(i)
                                    i = '$' + i.toLocaleString()
                                } else if (typeof i === 'number' && i < 0) {
                                    i = Math.round(i) * -1
                                    i = '-$' + i.toLocaleString()
                                }
                                return <TableCell>{i}</TableCell>
                            })}
                        </TableRow>)
                    })}
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}