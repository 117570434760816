import React from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
    Title,
    MenuLink,
} from './NavbarElements';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey'
import useWindowDimensions from '../ResizeHook';
import MenuIcon from '@mui/icons-material/Menu';

const theme = createTheme({
  palette: {
    primary: grey
  },
});

const Navbar = (props) => {
    const {
        loggedIn = false
    } = props

    const { height, width } = useWindowDimensions();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
            <Nav>
                {(width < 768) ? <>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '2%'}}>
                        <NavLink to='/' activeStyle>
                            <Title>
                                Top Down Database
                            </Title>
                        </NavLink>
                        <Button
                            id='menuDropdown'
                            aria-controls={open ? 'orgNumDropdown' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MenuIcon htmlColor='white' />
                        </Button>
                        <Menu
                            id='menuDropdown'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            style={{marginLeft: '20px', textAlign: 'left'}}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                // onMouseLeave: handleClose
                            }}
                        >
                            <MenuItem>
                                <MenuLink to='map' onClick={handleClose} activeStyle>
                                    <div style={{textAlign: 'left'}}>
                                        Census Map
                                    </div>
                                </MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to='campaign_contributions' onClick={handleClose} activeStyle>
                                        Campaign<br/>Contributions
                                </MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to='chart' onClick={handleClose} activeStyle>
                                    BLS Chart
                                </MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to='budget' onClick={handleClose} activeStyle>
                                    Income Security<br/>Budget
                                </MenuLink>
                            </MenuItem>
                            {(loggedIn) ? (
                                <MenuItem>
                                    <MenuLink to='aram' onClick={handleClose} activeStyle>
                                        ARAM
                                    </MenuLink>
                                </MenuItem>
                            ): <></>}
                            <MenuItem>
                                <MenuLink to='/about' onClick={handleClose} activeStyle>
                                    About
                                </MenuLink>
                            </MenuItem>
                        </Menu>
                    </div>
                </> : <></>}
                

                <NavMenu>
                    <NavLink to='/' activeStyle>
                        <Title>
                            Top Down Database
                        </Title>
                    </NavLink>
                    <NavLink to='map' activeStyle>
                        Census Map
                    </NavLink>
                    <NavLink to='campaign_contributions' activeStyle>
                        <center>
                            Campaign<br/>Contributions
                        </center>
                    </NavLink>
                    <NavLink to='chart' activeStyle>
                        BLS Chart
                    </NavLink>
                    <NavLink to='budget' activeStyle>
                        <center>
                            Income Security<br/>Budget
                        </center>
                    </NavLink>
                    {(loggedIn) ? (
                        <NavLink to='aram' activeStyle>
                            ARAM
                        </NavLink>
                    ): <></>}
                    {/* Second Nav */}
                    {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
                    <NavLink to='/about' activeStyle>
                        About
                    </NavLink>
                </NavMenu>

                {(loggedIn) ? (
                    <Button
                        variant="contained"
                        theme={theme}
                        style={{margin: 'auto', marginRight: '1vw'}}
                        onClick={()=>{
                        axios.get('/session/logout', {
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': 0
                            }
                        })
                        .then((response) => {
                            console.log('logout response', response.data)
                            window.location.href = window.location.origin
                        })
                        }}
                    >
                        Logout
                    </Button>
                ) : (
                    <NavBtn>
                    <NavBtnLink to='/login'>Sign In</NavBtnLink>
                </NavBtn>
                )}
                

            </Nav>


        </>
    );
};

export default Navbar;