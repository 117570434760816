import axios from "axios"
import { useEffect, useState } from "react"
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import tree from 'highcharts/modules/treemap';
import heatmap from 'highcharts/modules/heatmap'
import { statesAbbreviations, testData } from "../../consts";
import { CustomAutocomplete } from "../../components/CustomAutocomplete";
import { Chip, TextField } from "@mui/material";
import LoadingCircle from "../../components/LoadingCircle";

heatmap(Highcharts)
tree(Highcharts)

// Highcharts.theme = {
//     colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
//              '#FF9655', '#FFF263', '#6AF9C4'],
//     chart: {
//         backgroundColor: {
//             linearGradient: [0, 0, 500, 500],
//             stops: [
//                 [0, 'rgb(255, 255, 255)'],
//                 [1, 'rgb(240, 240, 255)']
//             ]
//         },
//     },
//     title: {
//         style: {
//             color: '#000',
//             font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
//         }
//     },
//     subtitle: {
//         style: {
//             color: '#666666',
//             font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
//         }
//     },
//     legend: {
//         itemStyle: {
//             font: '9pt Trebuchet MS, Verdana, sans-serif',
//             color: 'black'
//         },
//         itemHoverStyle:{
//             color: 'gray'
//         }
//     }
// };
// Apply the theme
// Highcharts.setOptions(Highcharts.theme);

const fetchData = async (setData, setLoading) => {
    setLoading(true)
    // const postBody = {"type":"recipient","filters":{"fy":"2023","period":"11","budget_function" :"600"}}
    // const request = await axios.post('https://api.usaspending.gov/api/v2/spending/', postBody)
    // const request = await axios.get('https://api.usaspending.gov/api/v2/recipient/state/')
    const request = await axios.get('/income_security_budget')

    console.log('request data', request.data)
    setData(request.data)
}


const updateCharts = (data, setOptions, filters, setLoading) => {
    setLoading(true)
    // const total = data.map((i)=>i.amount).reduce((a,b) => a+b)
    const total = data.total

    const seriesData = (filters.length > 0) ? data.results.filter((i)=> filters.some(v => i.id.includes(v.name.toUpperCase()) || i.id.includes(` ${v.abbreviation} `) || i.id.slice(0,3) == `${v.abbreviation} `)).slice(0,100).map((record) => {
        return { name: record.id, value: record.amount, colorValue: record.amount / total}
    }) : data.results.slice(0,100).map((record) => {
        return { name: record.id, value: record.amount, colorValue: record.amount / total}
    })

    // const seriesData = (filters.length > 0) ? data.filter((i)=> filters.some(v => i.name.includes(v.name.toUpperCase()) || i.name.includes(`${v.abbreviation} `))).slice(0,100).map((record) => {
    //     return { name: record.name, value: record.amount, colorValue: record.amount / total}
    // }) : data.slice(0,100).map((record) => {
    //     return { name: record.name, value: record.amount, colorValue: record.amount / total}
    // })

    // const data = testData

    const options = {
        colorAxis: {
            minColor: '#9eff8a',
            maxColor: '#0b4000',
        },
        series: [{
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            clip: false,
            data: seriesData
        }],
        title: {
            text: 'US Budget Breakdown',
            style: {
                color: 'white',
                fontSize: '2em'
            }
        },
        chart: {
            backgroundColor: '#4f4f4f',
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.3em',
                        textOutline: ''
                    }
                }
            } 
        },
    }


    console.log('options', options)
    setOptions(options)
    setLoading(false)
}

export const Treemap = () => {
    // const [data, setData] = useState({results: [], total: 0})
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState({
        colorAxis: {
            minColor: '#9eff8a',
            maxColor: '#0b4000',
        },
        series: [],
        title: {
            text: 'US Budget Breakdown',
            style: {
                color: 'white',
                fontSize: '2em'
            }
        },
        chart: {
            backgroundColor: '#4f4f4f',
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.3em',
                        textOutline: ''
                    }
                }
            } 
        },
    })
    const [filters, setFilters] = useState([])

    useEffect(()=>{
        fetchData(setData, setLoading)
    }, [])

    useEffect(()=>{
        if (data.results && data.results.length > 0) {
            updateCharts(data, setOptions, filters, setLoading)
        }
    }, [data, filters])


    return <>
        <div style={{height: 'calc(100vh - 100px', display: 'flex', flexDirection: 'column'}}>
            <div style={{ marginBottom: '1px', marginTop: '10px', width: '30vw', zIndex: 2, position: 'absolute' }}>
                <CustomAutocomplete
                    options={statesAbbreviations}
                    getOptionLabel={(state) => state.name}
                    renderInput={(params) => <TextField {...params} label='States' size='small' />}
                    onChange={(event, value)=>{
                        if (value && !filters.includes(value)) {
                            setFilters([...filters, value])
                        }
                    }}
                />
                <div>
                    {filters.map((filter) => {
                        return <Chip
                            label={filter.name}
                            sx={{ color: 'white'}}
                            variant="outlined"
                            onDelete={()=>{
                                setFilters(filters.filter((i) => i!=filter))
                            }}
                        />
                    })}
                </div>
            </div>
            {(loading) ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', zIndex: 2, height: '50vh', width: '100vw'}}>
                    <LoadingCircle />
                </div>
            ) : <></>}
            <div style={{height: 'calc(100vh - 120px)'}}>
                <HighchartsReact 
                    highcharts={Highcharts}
                    options={options}
                    containerProps={{style: {height: '100%', width: '100%', zIndex: 1}}}
                />
            </div>
            
            
        </div>
        
    </>
}