import React, {useState, useEffect} from 'react';
import LineChart from '../components/LineChart';
import LoadingCircle from '../components/LoadingCircle';
import axios from 'axios';

export const Wages = () => {
    const [data, setData] = useState(null);

    const fetchData = async () => {
        axios.post('/labor/CES0500000003')
        .catch((errors) => console.log(errors))
        .then((response) => {
            console.log('data', response.data)
            setData(response.data)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
        {(data != undefined) ?
            <LineChart title='Total Private Average Hourly Earnings of All Employees' xAxis={data.month} series={data.series} />
        : <LoadingCircle />}
        </>
    )
}