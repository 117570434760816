import React from 'react';
import { ExternalLink } from '../components/Navbar/NavbarElements';
import mapPage from '../assets/map-page.png';
import chartPage from '../assets/chart-page.png';
import campaignPage from '../assets/campaign-page.png';
import budgetPage from '../assets/budget-page.png';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useWindowDimensions from '../components/ResizeHook';


const PageTile = (props) => {
  const {
    title,
    image,
    description,
    sourceLink,
    sourceText,
    pageLink
  } = props

  const { height, width } = useWindowDimensions();


  return <>
    <div style={{ display: 'flex', flexDirection: (height > width) ? 'column' : 'row', alignItems: 'center', marginBottom: '5vh'}}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant='h4'>
          <NavLink to={pageLink} style={{textDecoration: 'none', color: 'white'}}>
            {title}
          </NavLink>
        </Typography>
        <NavLink to={pageLink}>
          <img src={image} alt={title} style={{ width: (height > width) ? '90vw': '55vw' }} />
        </NavLink>
      </div>
      
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '0 2vw' }}>
        <div>
          <Typography variant='h6'>Description:</Typography>
          <Typography>
            {description}
          </Typography>
        </div>

        <Typography>
          Source: <ExternalLink href={sourceLink} target='_blank'>{sourceText}</ExternalLink>
        </Typography>
      </div>
    </div>
  </>
}


const Home = () => {
  const censusMapDesc = 'Maps American Community Survey (ACS) data from the 2020 US Census by state. Default series is Ratio of Poverty to Income, showing the vast disparity of wealth between US states and territories. Click on a state to bring up a detailed breakdown of the data available for each respective series.'
  const blsChartDesc = 'Charts Bureau of Labor Statistics (BLS) data concurrently over time. Select series to compare in the "Add Data" pane and date ranges above the chart container. Data values can be normalized to percentage of change with the Normalize slider. The Smart Prompt field can be used to input a desired topic to display, relevant series will be selected by GPT-4.'
  const campaignDesc = 'Breaks down political contributions to campaign committees by SOC job classification. Source data was taken from FEC contributions dataset, job classifications were conflated with a machine learning algorithm trained on a dataset of 42 million job postings in the National Labor Exchange (NLx) Research Hub.'
  const budgetDesc = 'US Federal Government budget breakdown of money spent on income security. Filter for individual states and territories by using the input field left of the chart title.'

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 80px)',
        color: 'white',
        margin: '0 1vw',
        textShadow: 'black 2px 2px'
      }}
    >
      <PageTile
        title='Census Map'
        image={mapPage}
        description={censusMapDesc}
        sourceLink='https://www.census.gov/data/developers/data-sets.html'
        sourceText='Census.gov API'
        pageLink='map'
      />
      <PageTile
        title='Campaign Contributions'
        image={campaignPage}
        description={campaignDesc}
        sourceLink='https://www.fec.gov/data/'
        sourceText='FEC.gov Data'
        pageLink='campaign_contributions'
      />
      <PageTile
        title='BLS Chart'
        image={chartPage}
        description={blsChartDesc}
        sourceLink='https://www.bls.gov/data/'
        sourceText='BLS.gov API'
        pageLink='chart'
      />
      <PageTile
        title='Income Security Budget'
        image={budgetPage}
        description={budgetDesc}
        sourceLink='https://www.usaspending.gov/explorer/budget_function'
        sourceText='USA Spending.gov API'
        pageLink='budget'
      />
    </div>
  );
};

export default Home;