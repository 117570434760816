import React from 'react';
import { ExternalLink } from '../components/Navbar/NavbarElements';

const About = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'Left',
                alignItems: 'Left',
                height: '80vh',
                color: 'white',
                margin: '0 1vw',
                textShadow: 'black 2px 2px'
            }}
        >
            <h1>About</h1>
            <p>This is a sandbox website where I host my personal projects. Feel free to take a look around, or contact me at tboone@topdowndatabase.com</p>

            <h2 style={{marginTop: '5vh'}}>Links</h2>
            <ExternalLink
                href='https://github.com/taylorjboone'
                target='_blank'
            >
                GitHub
            </ExternalLink>
            <ExternalLink
                href='https://www.linkedin.com/in/taylor-j-boone/'
                target='_blank'
            >
                Linked In
            </ExternalLink>

        </div>
    );
};

export default About;