import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ErrorIcon from "@mui/icons-material/Error";
import { Typography, makeStyles } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function SimpleDialog(props) {
    const { handleClose, open } = props;

    return (
        <>
            <Dialog
                open={open}
                // onMouseEnter={()=>{
                //     console.log('here')
                //     handleClose()
                // }}
                onClick={()=>{
                    handleClose()
                }}
                PaperProps={{
                    style: {
                        position: 'absolute',
                        left: 0,
                        top: '40px',
                        height: '75px',
                        width: '175px',
                        padding: '5px',
                        paddingBottom: '0px'

                    }
                }}
            >
                <Typography>
                    Click a committee name to drilldown to job titles
                </Typography>
                <ArrowDropDownIcon
                    fontSize='large'
                    // style={{
                    //     position: 'absolute',
                    //     color: 'white',
                    //     left: '20px',
                    //     top: '140px',
                    //     zIndex: 2
                    // }}
                    style={{marginTop: '-10px', paddingBottom: 0, marginBottom: 0}}
                />
            </Dialog>
            
        </>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default function Popup(props) {
    const {open, setOpen} = props
    // const [open, setOpen] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        console.log('here')
        setOpen(false);
    };

    const closePopup = async () => {
        setTimeout(() => {
            setOpen(false)
        }, 10000)
    }

    useEffect(()=>{
        closePopup()
    }, [])

    return (
        <div>
            {/* <SimpleDialog open={open} onClose={handleClose} errors={errors} /> */}
            <SimpleDialog open={open} handleClose={handleClose} />
        </div>
    );
}
